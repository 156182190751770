import { useMutation } from "react-query";
import { QUERY_KEYS } from "@helpers/query";
import { getOrderStatusHistory } from "@services/dms.service";

const useGetOrderStatusHistory = () => {
  return useMutation({
    mutationKey: QUERY_KEYS.GET_ORDER_STATUS_HISTORY,
    mutationFn: getOrderStatusHistory,
  });
};

export default useGetOrderStatusHistory;
