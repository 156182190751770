import { TableCell, TableRow, useTheme } from "@mui/material";
import { Button, Icon, Menu } from "@ntpkunity/controls";
import { TableRowOptions } from "@helpers/enum";
import { CreditDocumentRowProps } from "./credit-document-row.props";
import { useProposalFieldArrays } from "@contexts/proposal-form";

const CreditDocumentRow = ({
  id,
  name,
  type,
  isMandatory,
  documents,
  setEditDocumentId,
  setOpenDialog,
  setOpenViewDialog,
}: CreditDocumentRowProps) => {
  const theme = useTheme();
  const {
    documentMethods: { remove, fields: creditDocuments },
  } = useProposalFieldArrays();
  const handleOptionClick = (_, key, value) => {
    if (key === TableRowOptions.UPLOAD) {
      setEditDocumentId(value);
      setOpenDialog(true);
    }
    if (key === TableRowOptions.VIEW) {
      setEditDocumentId(value);
      setOpenViewDialog(true);
    }
    if (key === TableRowOptions.DELETE) {
      remove(creditDocuments.findIndex((document) => document.id == value));
    }
  };

  return (
    <TableRow className="child-tr">
      <TableCell>{name}</TableCell>
      <TableCell>{type}</TableCell>
      <TableCell>{isMandatory ? "Mandatory" : "Optional"}</TableCell>
      <TableCell>
        {documents?.length > 0 ? (
          <>
            <span className="status-indicator ind-success"></span>Uploaded
          </>
        ) : (
          <>
            <span className="status-indicator ind-danger"></span>Missing
          </>
        )}
      </TableCell>
      <TableCell className="action-cell fixed-cell">
        <Menu
          theme={theme}
          options={[
            {
              optionText: "View Document",
              optionkey: TableRowOptions.VIEW,
              optionValue: id,
            },
            {
              optionText: "Upload Document",
              optionkey: TableRowOptions.UPLOAD,
              optionValue: id,
            },
          ]}
          handleOptionClick={handleOptionClick}
          render={(cb) => (
            <Button
              defaultBtn
              theme={theme}
              iconText={<Icon name="MoreIcon" />}
              onClick={cb}
            ></Button>
          )}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        />
      </TableCell>
    </TableRow>
  );
};

export default CreditDocumentRow;
