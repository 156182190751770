import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";

export const StatusButtonWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.status-button-wrap": {
    ".btn": {
      "&.btn-default": {
        padding: "8px 12px 8px 12px",
        fontWeight: theme.customVariables.fontWeightSemiBold,
        backgroundColor: "#f5f5f7",
        lineHeight: theme.typography.body1.lineHeight,
        "&.with-icon": {
          ".MuiButton-iconSizeMedium": {
            "&.MuiButton-startIcon": {
              marginRight: 4,
              width: 8,
              height: 8,
            },
          },
        },
        "&:hover": {
          backgroundColor: "#f5f5f7 !important",
        },
        ".status-indicator": {
          width: 8,
          height: 8,
          borderRadius: "50%",
          backgroundColor: theme.palette.primary.main,
        },
      },
    },
    "&.bg-light": {
      ".btn": {
        "&.btn-default": {
          backgroundColor: theme.palette.common.white,
          "&:hover": {
            backgroundColor: theme.palette.common.white + "!important",
          },
        },
      },
    },
    "&.status-primary": {
      ".btn": {
        ".status-indicator": {
          backgroundColor: theme.palette.primary.main,
        },
      },
    },
    "&.status-danger": {
      ".btn": {
        ".status-indicator": {
          backgroundColor: theme.palette.error.main,
        },
      },
    },
    "&.status-warning": {
      ".btn": {
        ".status-indicator": {
          backgroundColor: theme.palette.warning.main,
        },
      },
    },
    "&.status-info": {
      ".btn": {
        ".status-indicator": {
          backgroundColor: "#AC8E68",
        },
      },
    },
    "&.status-contract": {
      ".btn": {
        ".status-indicator": {
          backgroundColor: theme.palette.info.main,
        },
      },
    },
    "&.status-warning-dark": {
      ".btn": {
        ".status-indicator": {
          backgroundColor: "#FF9F0A",
        },
      },
    },
    "&.status-primary-light": {
      ".btn": {
        ".status-indicator": {
          backgroundColor: "#40CBE0",
        },
      },
    },
    "&.status-primary-dark": {
      ".btn": {
        ".status-indicator": {
          backgroundColor: "#5E5CE6",
        },
      },
    },
    "&.status-purple": {
      ".btn": {
        ".status-indicator": {
          backgroundColor: "#BF5AF2",
        },
      },
    },
    "&.status-success": {
      ".btn": {
        ".status-indicator": {
          backgroundColor: theme.palette.success.main,
        },
      },
    },
    "&.status-complied": {
      ".btn": {
        ".status-indicator": {
          backgroundColor: "#63E6E2",
        },
      },
    },
    "&.status-conditional-approval": {
      ".btn": {
        ".status-indicator": {
          backgroundColor: "#18807D",
        },
      },
    },
    "&.status-additional-info-required": {
      ".btn": {
        ".status-indicator": {
          backgroundColor: "#4D4D4D",
        },
      },
    },
    "&.selected": {
      ".btn": {
        "&.btn-default": {
          border: "1px solid" + theme.palette.primary.main,
        },
      },
    },
  },
}));
