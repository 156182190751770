import { useQuery } from "react-query";
import { QUERY_KEYS } from "@helpers/query";
import { getUmsPermissions } from "@services/ums.service";

const useGetPermissions = (product_id: number) => {
  return useQuery({
    queryKey: [QUERY_KEYS.GET_PRODUCT_ID, product_id],
    queryFn: () => getUmsPermissions(product_id),
    enabled: !!product_id,
  });
};
export default useGetPermissions;
