import { useTheme } from "@mui/material";
import { FinancialConfigTabsWrap } from "./config-tabs.style";
import { ScrollableTabs } from "@ntpkunity/controls";
import { FinancialConfigurationFields } from "@components";

export const FinancialConfigurationTabs = ({
  financeType,
  setFinanceType,
  financeConfigurations,
}: {
  financeType;
  setFinanceType;
  financeConfigurations;
}) => {
  const theme = useTheme();

  const handleTabChange = (newTabIndex: number) => {
    const selectedConfig = financeConfigurations[newTabIndex];
    if (selectedConfig) {
      setFinanceType(selectedConfig.code);
    }
  };
  const tabItems = financeConfigurations?.map((config, index) => ({
    title: config?.name,
    content: (
      <FinancialConfigurationFields financeType={financeType} key={index} />
    ),
  }));
  return (
    <FinancialConfigTabsWrap
      theme={theme}
      className="financial-config-tabs-wrap"
    >
      <ScrollableTabs
        theme={theme}
        items={tabItems}
        scrollButtons={"auto"}
        defaultTabIndex={0}
        onChange={(event: any, newTabIndex: number) =>
          handleTabChange(newTabIndex)
        }
      />
    </FinancialConfigTabsWrap>
  );
};
