export const setFavicon = (
  icon = "https://unitydmsbucket.s3.amazonaws.com/images/Link-Logo-icon.png"
) => {
  let favicon = document.querySelector("link[rel~='icon']") as any;
  if (!favicon) {
    favicon = document.createElement("link");
    favicon.rel = "icon";
    document.getElementsByTagName("head")[0].appendChild(favicon);
  }
  favicon.href = icon;

  const logo = document.getElementById("unity_logo_icon") as any;
  if (!logo) return;

  logo.src = icon;
};

export const setStyleSheet = (stylesheet = "theme-builder.css") => {
  let style = document.querySelector("link[rel~='stylesheet']") as any;
  if (!style) {
    style = document.createElement("link");
    style.rel = "stylesheet";
    style.type = "text/css";
    style.target = "_blank";
    document.getElementsByTagName("head")[0].appendChild(style);
    style.href = stylesheet;
  }
  style.href = stylesheet;
};

export const setLogo = (
  icon = "src/public/assets/images/app-default-logo.svg"
) => {
  let logo = document.getElementById("unity_logo") as any;
  if (!logo) return;
  logo.src = icon;
};
