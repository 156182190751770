import { GET_COMPANIES, SEARCH_ADRESS } from "@helpers/endpoints";
import { hubexAPI } from "./instances";
import { CompanySearchReqParams } from "@_types";
import { getQueryParamsString } from "@helpers/utils";

export const searchCompanies = async (
  xServiceProvider: string,
  params: CompanySearchReqParams
) => {
  const queryString = getQueryParamsString(params);
  return hubexAPI
    .get(`${GET_COMPANIES}${queryString}`, {
      headers: { "x-service-provider": xServiceProvider },
    })
    .then((res) => res.data);
};

export const searchAddress = async (
  xServiceProvider: string,
  postCode: string
) => {
  return hubexAPI
    .get(`${SEARCH_ADRESS}?postcode=${postCode}`, {
      headers: { "x-service-provider": xServiceProvider },
    })
    .then((res) => res.data);
};
