import {
  LayoutWithSideNavComponent,
  QuotationPageContent,
  Snackbar,
} from "@components";
import { useTheme } from "@mui/material";
import { CalculationsContextProvider } from "@ntpkunity/controls-common";

export const NewQuotationPage = () => {
  const theme = useTheme();

  return (
    <LayoutWithSideNavComponent theme={theme}>
      <CalculationsContextProvider>
        <QuotationPageContent />
      </CalculationsContextProvider>
      <Snackbar />
    </LayoutWithSideNavComponent>
  );
};
