import { Box, Dialog } from "@ntpkunity/controls";
import { useTheme } from "@mui/material";
import { AuditTrail } from "./audit-trail.component";
import { AuditTrailWrap } from "./audit-trail.style";

export const AuditTrailDialog = ({
  open,
  onClose,
  proposalId,
}: {
  open: boolean;
  onClose: () => void;
  proposalId?: string;
}) => {
  const theme = useTheme();
  return (
    <Dialog
      theme={theme}
      size="sm"
      title="Audit Trail"
      onCloseDialog={onClose}
      noFooter
      open={open}
    >
      <AuditTrailWrap theme={theme} className="audit-trail-popup-wrap">
        <AuditTrail proposalId={proposalId} />
      </AuditTrailWrap>
    </Dialog>
  );
};
