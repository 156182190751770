import "react";
import { Accordion, Box, Button, Icon, Menu } from "@ntpkunity/controls";
import { useTheme } from "@mui/material";
import { AddSignatoriesListWrap } from "./add-signatories.style";
import SignatoriesTable from "./signatories-table.component";
import { Template } from "@_types/setups";
import { useParams } from "react-router-dom";

const SignatoriesList = ({
  complianceDocuments,
  signatories,
  commonFilterParams,
  isGeneratedDocument,
}) => {
  const theme = useTheme();
  const params = useParams<{ proposalId?: string }>();
  return (
    complianceDocuments.length > 0 && (
      <AddSignatoriesListWrap
        theme={theme}
        className="add-signatories-list-wrap"
      >
        <Box
          theme={theme}
          className="collapse-wrap list-collapse with-border"
          mt={2}
        >
          <Accordion
            theme={theme}
            items={complianceDocuments.flatMap((item) =>
              item.templates.map((template: Template, index: number) => ({
                content: (
                  <>
                    <SignatoriesTable
                      signatories={signatories?.filter(
                        (signatory) =>
                          signatory.stakeholder_reference_id ===
                            template.template_name &&
                          signatory.order_identifier === params?.proposalId
                      )}
                      commonFilterParams={commonFilterParams}
                      referenceId={template.template_name}
                      isGeneratedDocument={isGeneratedDocument}
                    />
                  </>
                ),
                title: (
                  <>
                    <span className="title-with-icon">
                      <Icon name="DocumentIcon" />
                    </span>
                    {template.template_name.replace(".docx", "")}
                  </>
                ),
                key: "string",
                isExpanded: true,
                id: "string",
                ariaControls: "string",
              }))
            )}
            shouldCollapse
          />
        </Box>
      </AddSignatoriesListWrap>
    )
  );
};

export default SignatoriesList;
