import { useMutation } from "react-query";
import { executeWorkflow } from "@services/workflows.service";
import { useGetCompanyByTenantId } from "@hooks/queries";

const useExecuteWorkflow = () => {
  const { data: companyInfo } = useGetCompanyByTenantId();

  return useMutation({
    mutationFn: async () => executeWorkflow(companyInfo?.slug),
  });
};

export default useExecuteWorkflow;
