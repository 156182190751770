import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";

export const PageContent = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.page-content": {
    [theme.breakpoints.down("md")]: {
      paddingTop: 163,
    },
  },
}));

export const ConfigurationsPageContentWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(() => ({
  "&.configurations-page-content-wrap": {
    ".full-page-alert-wrap": {
      "&.full-page": {
        minHeight: "calc(100vh - 73px)",
      },
    },
  },
}));
