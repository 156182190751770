import { OrderStatus } from "@helpers/enum";
import { clsx } from "clsx";

const DealerProposalStatus = ({ status }: { status: OrderStatus }) => {
  return (
    <>
      <span
        className={clsx("status-indicator", {
          "ind-contract": status === OrderStatus.NEW,
        })}
      ></span>
      {status}
    </>
  );
};

export default DealerProposalStatus;
