import React from "react";
import { IconButton, Divider } from "@mui/material";
import {
  Icon,
  type ISideBarLayoutProps,
  Grid,
  Typography,
  Avatar,
  Box,
  List,
  Switch as CustomizedSwitches,
  Menu,
  Input,
} from "@ntpkunity/controls";

import { logout } from "@ntpkunity/controls-ums";
import {
  CustomizedContainer,
  CustomizedDrawer,
  LayoutWrap,
} from "@components/layout/LayoutWithSideNav/LayoutWithSideNav.style";
import LogoIcon from "@assets/images/app-default-logo-icon.svg";
import Logo from "@assets/images/app-default-logo.svg";
import { ActionOptions } from "@helpers/enum";
import { LOCAL_STORAGE_KEYS } from "@helpers/const";
import { useQueryClient } from "react-query";
import { QUERY_KEYS } from "@helpers/query";
import { getUserNameInitials, getUserSettings } from "@helpers/utils";
import { useNavItems } from "@hooks";

export const LayoutWithSideNavComponent: React.FC<ISideBarLayoutProps> = ({
  children,
  theme,
}) => {
  const queryClient = useQueryClient();

  const [open, setOpen] = React.useState(false);

  const handleRootOptionsClick = () => setOpen(true);
  const accountSettings = JSON.parse(
    localStorage.getItem(LOCAL_STORAGE_KEYS.SETTINGS)
  );
  const companyProfile: any = queryClient.getQueryData([
    QUERY_KEYS.GET_COMPANY_PROFILE,
    accountSettings?.tenant_id,
  ]);
  const ThemeBuilderData: any = queryClient.getQueryData([
    QUERY_KEYS.GET_THEME_BUILDER,
    companyProfile?.email,
  ]);
  const user_name = getUserSettings()?.user_name;
  const userNameInitials = getUserNameInitials(user_name);

  const handleOptionClick = (event, key, value) => {
    switch (key) {
      case ActionOptions.LOGOUT:
        logout();
        localStorage.clear();
        return;
    }
  };

  const sideMenuList = useNavItems({
    handleRootOptionsClick: handleRootOptionsClick,
  });

  const MenuComp = (): JSX.Element => {
    return (
      <Menu
        theme={theme}
        disablePortal={true}
        handleOptionClick={handleOptionClick}
        options={[{ optionText: "Logout", optionkey: "Logout" }]}
        render={(cb) => (
          <>
            <a
              className="header-link-item user-link"
              href="javascript:void(0)"
              onClick={cb}
            >
              <Box theme={theme} className="user-item">
                <Avatar theme={theme}>{userNameInitials}</Avatar>
                <Box theme={theme} className="user-info">
                  <Typography
                    theme={theme}
                    className="fw-sbold text-dark"
                    variant="caption"
                    component={"small"}
                    display={"block"}
                  >
                    {accountSettings?.user_name ?? ""}
                  </Typography>
                  <Typography
                    theme={theme}
                    className="text-muted text-tiny"
                    variant="caption"
                    component={"small"}
                    display={"block"}
                  >
                    {accountSettings?.email ?? ""}
                  </Typography>
                </Box>
              </Box>
            </a>
          </>
        )}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      />
    );
  };

  return (
    <LayoutWrap theme={theme} component="div">
      <Box theme={theme} component="aside" className="u-aside-wrap">
        <CustomizedDrawer
          theme={theme}
          variant="permanent"
          open={open}
          className="u-side-nav"
        >
          <Box theme={theme} className="menu-top-wrap">
            <Box
              className="menu-action"
              theme={theme}
              display={{ xs: "block", md: "none" }}
            >
              <IconButton
                onClick={() => setOpen(!open)}
                className="menu-toggle"
              >
                <Box theme={theme} className="menu-icon">
                  <Box theme={theme} className="toggle-bar"></Box>
                </Box>
              </IconButton>
            </Box>
            <div className="logo-wrap">
              <div className="logo-area">
                <img
                  id="unity_logo_icon"
                  src={
                    ThemeBuilderData && ThemeBuilderData?.favicon?.location
                      ? ThemeBuilderData?.favicon?.location
                      : LogoIcon
                  }
                  alt="logo"
                  className="logo-icon"
                />
                <img
                  id="unity_logo"
                  src={
                    ThemeBuilderData && ThemeBuilderData?.logo?.location
                      ? ThemeBuilderData?.logo?.location
                      : Logo
                  }
                  alt="logo"
                  className="full-logo"
                />
              </div>
              <Divider />
            </div>
            <Box
              className="header"
              theme={theme}
              display={{ xs: "flex", md: "none" }}
              gap={2}
            >
              <a
                className="header-link-item item-icon-only link-search"
                href="javascript;"
              >
                <Icon name="SearchIcon" />
              </a>
              <a
                className="header-link-item item-icon-only link-notification"
                href="javascript;"
              >
                <Icon name="BellIcon" />
                <span className="notify-icon"></span>
              </a>
            </Box>
          </Box>
          <div className="side-nav-area-wrap">
            <div className="aside-content-wrap content-top">
              <List theme={theme} options={sideMenuList} />
            </div>
            <div className="aside-content-wrap">
              <Divider />
              <Typography
                theme={theme}
                variant="caption"
                component="small"
                className="nav-group-title"
              >
                Support
              </Typography>
              <List
                theme={theme}
                options={[
                  {
                    icon: <Icon name="DocumentIcon" />,
                    label: "Documentation",
                    hideLabel: false,
                    hideIcon: false,
                    onClick: () =>
                      window.open(process.env.DOCUMENTATION_URL, "_blank"),
                    listItemButtonSx: undefined,
                    listItemIconSx: undefined,
                    listItemTextSx: undefined,
                  },
                ]}
              />
            </div>
          </div>
          <div className="aside-content-wrap action-content">
            <Divider />
            <div className="side-nav-actions">
              <IconButton
                className="toggle-nav-btn"
                onClick={() => {
                  setOpen(!open);
                }}
              >
                <Icon name="ChevronRight" />
              </IconButton>
              <Box
                className="user-account"
                theme={theme}
                display={{ xs: "flex", md: "none" }}
              >
                <MenuComp />
                <div className="header-link-item item-theme-switch">
                  <CustomizedSwitches
                    onChange={(e) => {}}
                    theme={theme}
                  ></CustomizedSwitches>
                </div>
              </Box>
            </div>
          </div>
        </CustomizedDrawer>
      </Box>
      <CustomizedContainer theme={theme} open={open} component="main">
        <Box
          theme={theme}
          component="header"
          display={{ xs: "none", md: "flex" }}
        >
          <Grid theme={theme} direction="row" container>
            <Grid theme={theme} item xs={6} md={6}>
              <Box theme={theme} className="header-search" display={"none"}>
                <Input
                  theme={theme}
                  placeholder="What are you looking for?"
                  startAdornment={<Icon name="SearchIcon" />}
                  type={"text"}
                  fullWidth
                />
              </Box>
            </Grid>
            <Grid
              theme={theme}
              item
              display="flex"
              justifyContent="right"
              alignItems={"center"}
              gap={"24px"}
              xs={6}
              md={6}
              className="header-nav-right"
            >
              <div className="header-link-item item-theme-switch">
                <CustomizedSwitches
                  onChange={(e) => {}}
                  theme={theme}
                ></CustomizedSwitches>
              </div>
              <a
                className="header-link-item item-icon-only link-notification"
                href="javascript;"
              >
                <Icon name="BellIcon" />
                <span className="notify-icon"></span>
              </a>
              <MenuComp />
            </Grid>
            <Divider />
          </Grid>
        </Box>
        <Box theme={theme} className="content-container">
          {children}
        </Box>
      </CustomizedContainer>
    </LayoutWrap>
  );
};

export default LayoutWithSideNavComponent;
