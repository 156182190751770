import { useQuery } from "react-query";
import { QUERY_KEYS } from "@helpers/query";
import { getAssetCategories } from "@services/index.service";
import { AssetCategoriesSetupRes } from "@_types/setups";
import useGetCompanyByTenantId from "./useGetCompanyProfile";

const useGetAssetCategories = () => {
  const { data: companyInfo } = useGetCompanyByTenantId();
  const companyId = companyInfo?.id;

  return useQuery<number, unknown, AssetCategoriesSetupRes>({
    queryKey: [QUERY_KEYS.GET_ASSET_CATEGORIES, companyId],
    queryFn: () => getAssetCategories(companyId),
    enabled: !!companyId,
  });
};

export default useGetAssetCategories;
