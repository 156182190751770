import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";

export const DialogContentContainer = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.dialog-content-container": {
    ".text-danger": {
      color: theme.palette.error.main,
    },
    ".u-custom-file-upload": {
      marginBottom: 0,
      ".root-drag-file": {
        height: 254,
        "&.is-loading": {
          ".file-upload-label": {
            ".file-uploading-wrap": {
              flexDirection: "column",
              gap: 8,
              ".loader-wrap, .loading-label": {
                marginRight: 0,
              },
              ".file-del": {
                display: "none",
              },
            },
          },
        },
      },
    },
    ".file-preview": {
      marginTop: 8,
    },
  },
}));
