import { useQuery } from "react-query";
import { QUERY_KEYS } from "@helpers/query";
import { getDealerProfile } from "@services/index.service";
import { DealerProfile } from "@_types/company";

const useGetDealerProfile = (dealerCode: string) => {
  return useQuery<number, unknown, DealerProfile>({
    queryKey: [QUERY_KEYS.GET_DEALER_PROFILE, dealerCode],
    queryFn: () => getDealerProfile(dealerCode),
    enabled: !!dealerCode,
  });
};

export default useGetDealerProfile;
