import { useQuery } from "react-query";
import { QUERY_KEYS } from "@helpers/query";
import { getAssetTypes } from "@services/index.service";
import { AssetTypesSetupRes } from "@_types/setups";
import useGetCompanyByTenantId from "./useGetCompanyProfile";

const useGetAssetTypes = () => {
  const { data: companyInfo } = useGetCompanyByTenantId();
  const companyId = companyInfo?.id;

  return useQuery<number, unknown, AssetTypesSetupRes>({
    queryKey: [QUERY_KEYS.GET_ASSET_TYPES, companyId],
    queryFn: () => getAssetTypes(companyId),
    enabled: !!companyId,
  });
};

export default useGetAssetTypes;
