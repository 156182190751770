import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";

export const PageContent = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.page-content": {
    ".tabs-area": {
      marginBottom: -16,
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },
    [theme.breakpoints.down("md")]: {
      paddingTop: 104,
    },
  },
}));

export const DiaryContent = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.diary-content": {},
}));
