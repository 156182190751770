import { useTheme } from "@mui/material";
import { AuthLayoutWrap } from "./auth-layout.style";
import { ReactNode } from "react";
import { Box } from "@ntpkunity/controls";

export const AuthLayout = ({
  children,
  logoImage,
  sideBarImage,
}: {
  children?: ReactNode;
  logoImage?: string;
  sideBarImage?: string;
}) => {
  const theme = useTheme();

  return (
    <>
      <AuthLayoutWrap theme={theme} className="auth-layout-wrap">
        <Box theme={theme} className="auth-left-img">
          <img src={sideBarImage} alt="login" />
        </Box>
        <Box theme={theme} className="auth-right-panel">
          <Box theme={theme} className="right-content">
            <Box theme={theme} className="logo-area" mb={3}>
              <img src={logoImage} alt="Logo" />
            </Box>
            {children}
          </Box>
        </Box>
      </AuthLayoutWrap>
    </>
  );
};
