import { useTheme } from "@mui/material";
import { FinancialConfigFeeWrap } from "./fee.style";
import {
  Box,
  Button,
  Grid,
  Icon,
  Input,
  Select,
  Typography,
} from "@ntpkunity/controls";
import { FC } from "react";
import { Controller, useFormContext } from "react-hook-form";
import {
  ADD_FEES_DEFAULT_VALUES,
  FEE_FREQUENCIES,
  FEE_OCCURANCE_PERIODIC,
  FEE_OCCURANCES_ONE_TIME,
} from "@helpers/const";
import { FeeType, TRUE_FALSE_VALUES } from "@ntpkunity/controls-common";

export const FinancialConfigurationFee = ({
  financeType,
}: {
  financeType: string;
}) => {
  const theme = useTheme();
  const { control, setValue, watch } = useFormContext();

  let fees = watch(`${financeType}.fees`);

  const handleDeleteItem = (index: number) => {
    if (fees && fees.length > 0) {
      const updatedFees = fees.filter((_, i) => i !== index);
      setValue(`${financeType}.fees`, updatedFees);
    }
  };

  const handleAddItem = () => {
    fees?.push({ ...ADD_FEES_DEFAULT_VALUES });
    setValue(`${financeType}.fees`, fees);
  };
  return (
    <FinancialConfigFeeWrap theme={theme} className="financial-config-fee-wrap">
      <Typography theme={theme} component={"h4"} variant="h4">
        Fees
      </Typography>
      {fees?.map((fee, index) => {
        return (
          <Box theme={theme} className="fee-item" mt={3} key={index}>
            <Grid theme={theme} container item rowSpacing={3} columnSpacing={2}>
              <Grid theme={theme} item xs={12} md={2.5}>
                <Controller
                  name={`${financeType}.fees.${index}.name`}
                  control={control}
                  render={({ field }) => (
                    <Input
                      key={fee.id}
                      theme={theme}
                      fullWidth
                      {...field}
                      label="Fee name"
                      placeholder="Type here..."
                      type="text"
                    />
                  )}
                />
              </Grid>
              <Grid theme={theme} item xs={12} md={1.75}>
                <Controller
                  name={`${financeType}.fees.${index}.type`}
                  control={control}
                  render={({ field }) => (
                    <Select
                      key={fee.id}
                      theme={theme}
                      {...field}
                      fullWidth
                      label="Fee Type"
                      items={FEE_FREQUENCIES}
                    />
                  )}
                />
              </Grid>
              <Grid theme={theme} item xs={12} md={2.5}>
                <Controller
                  name={`${financeType}.fees.${index}.frequency`}
                  control={control}
                  render={({ field }) => (
                    <Select
                      key={fee.id}
                      theme={theme}
                      {...field}
                      fullWidth
                      label="Fee Frequency"
                      items={
                        watch(`${financeType}.fees.${index}.type`) ==
                        FeeType.ONE_TIME
                          ? FEE_OCCURANCES_ONE_TIME
                          : FEE_OCCURANCE_PERIODIC
                      }
                      onChange={(e) => field.onChange(e.target.value)}
                    />
                  )}
                />
              </Grid>
              <Grid theme={theme} item xs={6} md={1.75}>
                <Controller
                  name={`${financeType}.fees.${index}.firstFeePaymentDueAtStartDate`}
                  control={control}
                  render={({ field }) => (
                    <Select
                      key={fee.id}
                      theme={theme}
                      {...field}
                      fullWidth
                      label="Due At Start Date"
                      items={TRUE_FALSE_VALUES}
                      disabled={
                        watch(`${financeType}.fees.${index}.type`) ==
                        FeeType.ONE_TIME
                          ? true
                          : false
                      }
                    />
                  )}
                />
              </Grid>
              <Grid theme={theme} item xs={6} md={1.75}>
                <Controller
                  name={`${financeType}.fees.${index}.amount`}
                  control={control}
                  render={({ field }) => (
                    <Input
                      key={fee.id}
                      theme={theme}
                      {...field}
                      masking
                      maskDecimalScale={2}
                      maskNumeric
                      fullWidth
                      label="Fee Amount"
                      placeholder="Type here..."
                      type="decimal"
                    />
                  )}
                />
              </Grid>
              <Grid theme={theme} item xs={12} md={1.75}>
                <Controller
                  name={`${financeType}.fees.${index}.isEditable`}
                  // key={fee.id}
                  control={control}
                  render={({ field }) => (
                    <Box
                      theme={theme}
                      display={"flex"}
                      alignItems={"center"}
                      gap={2}
                      className="field-box"
                    >
                      <Select
                        {...field}
                        theme={theme}
                        fullWidth
                        label="Editable"
                        items={TRUE_FALSE_VALUES}
                      />
                      <Box theme={theme} className="fee-action">
                        <Button
                          theme={theme}
                          secondary
                          iconText={<Icon name="DeleteIcon" />}
                          onClick={() => {
                            handleDeleteItem(index);
                          }}
                        />
                      </Box>
                    </Box>
                  )}
                />
              </Grid>
            </Grid>
          </Box>
        );
      })}
      <Box theme={theme} mt={3}>
        <Button
          theme={theme}
          fullWidth
          secondary
          text="Add Fee"
          onClick={() => {
            handleAddItem();
          }}
        />
      </Box>
    </FinancialConfigFeeWrap>
  );
};
