import { Stack } from "@components";
import { Divider, useTheme } from "@mui/material";
import { Accordion, Box, Grid, Typography } from "@ntpkunity/controls";
import { Rentals } from "./rentals/rentals.component";
import { useSumOfAssetCost } from "@hooks/proposal";
import { pounds } from "@helpers/utils";
import Rates from "./rates/rates.component";
import Entry from "./entry/entry.component";
import {
  QuotationForm,
  useCalculationsContext,
  useQuotationFormContext,
} from "@ntpkunity/controls-common";
import { isDealerRole } from "@helpers/utils";

export const PaymentDetails = () => {
  const theme = useTheme();
  const totalAssetCost = useSumOfAssetCost();
  const _isDealerRole = isDealerRole();
  const { data: calculations } = useCalculationsContext();
  const { watch } = useQuotationFormContext<QuotationForm>();

  const tenure =
    Number(watch("noOfRegularPayments")) +
    (Number(watch("balloonPayment")) > 0
      ? Number(watch("balloonCollection"))
      : 0);

  return (
    <Stack paddingXs={3} paddingMd={3}>
      <Typography
        theme={theme}
        component="h4"
        variant="h4"
        children={pounds.format(totalAssetCost)}
      />
      <Typography
        theme={theme}
        component="small"
        variant="caption"
        className="subtitle"
        children="Total Asset Cost"
      />

      <Divider className="divider" />

      <Grid theme={theme} container columnSpacing={3} rowSpacing={2}>
        <Entry
          title="Finance Amount"
          value={`${pounds.format(calculations.financeAmount)}`}
        />
        {_isDealerRole && (
          <Entry
            title="Flat Rate"
            value={`${calculations.rates.flatRateExclCommission.toFixed(2)}%`}
          />
        )}

        <Entry title="Tenure" value={`${tenure} months`} />
      </Grid>
      <Box theme={theme} className="accordion-list-item">
        <Accordion
          theme={theme}
          items={[
            {
              title: "Upfront Payment:",
              subtitle: pounds.format(
                Number(calculations.depositAmount) +
                  Number(calculations.sumOfAdvanceRentals)
              ),
              content: (
                <>
                  <Box theme={theme} pl={3} pt={2}>
                    <Grid
                      theme={theme}
                      container
                      columnSpacing={3}
                      rowSpacing={2}
                    >
                      <Entry
                        title="Advance Rentals:"
                        value={pounds.format(
                          Number(calculations.sumOfAdvanceRentals)
                        )}
                      />
                      <Entry
                        title="Deposit:"
                        value={pounds.format(
                          Number(calculations.depositAmount)
                        )}
                      />
                    </Grid>
                  </Box>
                </>
              ),
              key: "string-3",
              isExpanded: true,
            },
          ]}
        />
      </Box>
      <Divider className="divider" />
      <Typography
        theme={theme}
        component="h4"
        variant="h4"
        children="Payment Details"
        sx={{ mb: 2 }}
      />
      <Rentals />

      {!_isDealerRole && (
        <>
          <Divider className="divider" />
          <Grid theme={theme} container columnSpacing={3} rowSpacing={2}>
            <Rates />
          </Grid>
        </>
      )}
    </Stack>
  );
};
