import { useTheme } from "@mui/material";
import { PostCodeDialogContent } from "./lookup-post-code-dialog.style";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  Grid,
  Input,
  Typography,
} from "@ntpkunity/controls";
import { Controller, useForm, useFormContext } from "react-hook-form";
import { TAddressLookupForm, TProposalForm } from "@_types/forms";
import { useSearchAddress } from "@hooks/queries";
import { useState } from "react";
import { SearchAddressResponse } from "@_types/proposal";
import { AxiosError } from "axios";

type LookupByPostCodeDialogProps = {
  open: boolean;
  closeDialog: () => void;
};

export const LookupByPostCodeDialog = ({
  open,
  closeDialog,
}: LookupByPostCodeDialogProps) => {
  const theme = useTheme();
  const [postCode, setPostCode] = useState<string>();
  const [addresses, setAddresses] = useState<SearchAddressResponse[]>([]);
  const [selectedAddress, setSelectedAddress] =
    useState<SearchAddressResponse>();

  const { control, getValues, reset } = useForm<TAddressLookupForm>();
  const { setValue } = useFormContext<TProposalForm>();
  const {
    isError: errorInAddress,
    error: error,
    isLoading: addressLoading,
    isSuccess: addressLoaded,
  } = useSearchAddress(process.env.ORDNANCE_SURVEY, postCode, (data) =>
    setAddresses(data)
  );

  const _closeDialog = () => {
    reset();
    setPostCode(undefined);
    setAddresses([]);
    setSelectedAddress(undefined);
    closeDialog();
  };

  const handleAddAddress = () => {
    const addressSplit = selectedAddress.address.split(",");
    const addressLine1 = addressSplit.reduce((prev, cur, index) => {
      if (index < addressSplit.length - 2) {
        return prev + "," + cur;
      } else return prev;
    });
    setValue("customerDetails.address.addressLine1", addressLine1);
    setValue("customerDetails.address.addressLine2", "");
    setValue("customerDetails.address.zipCode", selectedAddress.postcode);
    setValue("customerDetails.address.city", selectedAddress.post_town);
    setValue("customerDetails.address.county", "");
    _closeDialog();
  };

  return (
    <Dialog
      theme={theme}
      size="sm"
      open={open}
      title="Lookup by post code"
      onCloseDialog={_closeDialog}
      noFooter={!addressLoaded}
      customFooter={
        addressLoaded && (
          <>
            <Button
              theme={theme}
              secondary
              text="Cancel"
              type="button"
              onClick={_closeDialog}
            />
            <Button
              theme={theme}
              primary
              text="Add Details"
              type="button"
              onClick={handleAddAddress}
            />
          </>
        )
      }
    >
      <PostCodeDialogContent theme={theme} className="post-code-dialog-content">
        <Grid theme={theme} container item spacing={2}>
          <Grid theme={theme} item xs={9}>
            <Controller
              name="postCode"
              control={control}
              render={({ field }) => {
                return (
                  <Input
                    theme={theme}
                    type="text"
                    placeholder="Type Here..."
                    label="Enter Postal Code"
                    fullWidth
                    {...field}
                  />
                );
              }}
            />
          </Grid>
          <Grid theme={theme} item xs={3}>
            <Button
              theme={theme}
              text="Lookup"
              type="button"
              fullWidth
              primary
              onClick={() => setPostCode(getValues().postCode)}
            />
          </Grid>
        </Grid>
        {!!postCode && (
          <Box theme={theme} className="post-code-list-wrap" mt={3}>
            <Typography theme={theme} component={"p"} variant="subtitle1">
              Select Address
            </Typography>
            <Box theme={theme} className="post-code-list">
              {errorInAddress || addressLoading || !addresses.length ? (
                <Typography
                  theme={theme}
                  variant="body2"
                  component="span"
                  display="block"
                  textAlign="center"
                >
                  {errorInAddress
                    ? ((error as AxiosError).response?.data as any)?.detail
                        ?.message ||
                      "There was an error while searching addresses"
                    : addressLoading
                    ? "Searching Addresses..."
                    : "No Address found."}
                </Typography>
              ) : (
                addresses.map((address) => (
                  <Checkbox
                    key={address.address}
                    theme={theme}
                    label={address?.address}
                    checkBoxChecked={
                      selectedAddress?.address === address.address
                    }
                    onChange={(_, checked) => {
                      if (checked) {
                        setSelectedAddress(address);
                      } else setSelectedAddress(undefined);
                    }}
                  />
                ))
              )}
            </Box>
          </Box>
        )}
      </PostCodeDialogContent>
    </Dialog>
  );
};
