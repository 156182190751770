import { useTheme } from "@mui/material";
import { Box, Button, Icon, Typography } from "@ntpkunity/controls";
import { Stack } from "@components/stack";
import { useState } from "react";
import { TotalAssetCostWrap } from "./total-asset-cost.style";
import { PaymentDetails } from "./payment-details/payment-details.component";

export const TotalAssetCost = () => {
  const theme = useTheme();
  const [toggleBtn, setToggleBtn] = useState(false);

  const handleChange = () => {
    setToggleBtn(!toggleBtn);
  };

  return (
    <TotalAssetCostWrap theme={theme} className="total-asset-cost-wrap">
      <Box
        theme={theme}
        mb={2}
        position={"relative"}
        className={toggleBtn ? "isToggle" : ""}
      >
        <Stack paddingXs={3} paddingMd={3} className="toggle-stack">
          <Box theme={theme} className="stack-heading">
            <Typography theme={theme} component="h2" variant="h2">
              £ 00,000.00
            </Typography>
            <Typography
              theme={theme}
              component="p"
              variant="body2"
              className="text-muted"
              children="Total Asset Cost"
            />
          </Box>
          <Box theme={theme}>
            <PaymentDetails />
          </Box>
        </Stack>
        <Box theme={theme} className="toggle-btn">
          <Button
            defaultBtn
            theme={theme}
            onClick={handleChange}
            iconText={<Icon name="ChevronDown" />}
          />
        </Box>
      </Box>
    </TotalAssetCostWrap>
  );
};
