import { Stack } from "@components";
import { GB_COUNTRY } from "@helpers/const";
import { useTheme } from "@mui/material";
import { Accordion, AddressLayout, Typography } from "@ntpkunity/controls";
import { TProposalForm } from "@_types";
import { useFormContext } from "react-hook-form";
import { LookupByPostCodeDialog } from "@components/proposal/lookup-post-code-dialog/lookup-post-code-dialog.component";
import { useDialogState } from "@hooks";

type TAddressFromControl = {
  address_line_1: string;
  address_line_2: string;
  city: string;
  zip_code: string;
  county: string;
};

export const AddressDetails = () => {
  const theme = useTheme();

  const { setValue, watch } = useFormContext<TProposalForm>();
  const { dialogOpen, openDialog, closeDialog } = useDialogState();

  const handleAddressChange = (address: TAddressFromControl) => {
    const addressStr = "customerDetails.address" as const;
    setValue(`${addressStr}.addressLine1`, address.address_line_1);
    setValue(`${addressStr}.addressLine2`, address.address_line_2);
    setValue(`${addressStr}.city`, address.city);
    setValue(`${addressStr}.zipCode`, address.zip_code);
    setValue(`${addressStr}.county`, address.county);
  };

  const address = watch("customerDetails.address");

  return (
    <>
      <Stack
        paddingXs={3}
        paddingMd={3}
        marginTopMd={2}
        marginTopXs={2}
        className="collapse-wrap desktop-disable"
      >
        <Accordion
          theme={theme}
          items={[
            {
              content: (
                <>
                  <AddressLayout
                    theme={theme}
                    state={{
                      data: [],
                      selectedItemObject: {
                        text: "",
                        value: "",
                      },
                      controlName: "",
                    }}
                    isContactRequired={false}
                    addressVerificationRequired={false}
                    value={
                      {
                        address_line_1: address?.addressLine1,
                        address_line_2: address?.addressLine2,
                        city: address?.city,
                        zip_code: address?.zipCode,
                        county: address?.county,
                      } as TAddressFromControl
                    }
                    validationProps={undefined}
                    onChange={handleAddressChange}
                    hideAddressButton={true}
                    countries={[GB_COUNTRY]}
                    showCountryDropdown
                  />
                </>
              ),
              title: (
                <>
                  Address
                  <Typography
                    theme={theme}
                    variant="caption"
                    component="small"
                    className="text-primary fw-medium"
                    display={"block"}
                  >
                    <a
                      href="javascript:void(0)"
                      className="link text-primary decoration-none"
                      onClick={() => openDialog()}
                    >
                      Lookup by post code
                    </a>
                  </Typography>
                </>
              ),
              key: "string",
              isExpanded: true,
              id: "string",
              ariaControls: "string",
            },
          ]}
        />
      </Stack>
      <LookupByPostCodeDialog open={dialogOpen} closeDialog={closeDialog} />
    </>
  );
};
