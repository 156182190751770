import { useTheme } from "@mui/material";
import { CreditDocumentsWrap } from "@components/proposal/credit-documents/credit-document.style";
import {
  CreditDocumentTable,
  DocumentDialog,
} from "@components/proposal/credit-documents";
import { useState } from "react";
import { CreditDocumentProps } from "./credit-document.props";
import ViewDocumentDialog from "./document-dialog/view-document-dialog/view-document-dialog.component";
import { useGetDocumentTypes } from "@hooks/queries";
import { useFormContext } from "react-hook-form";
import { TProposalForm } from "@_types/forms";
import {
  useGetClientTypeName,
  useGetFinanceTypeName,
} from "@hooks/useSetupNames";

const CreditDocuments = ({ showAddBtn = true }: CreditDocumentProps) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [openViewDialog, setOpenViewDialog] = useState(false);
  const [editDocumentId, setEditDocumentId] = useState<null | string>(null);
  const theme = useTheme();

  const { watch } = useFormContext<TProposalForm>();
  const financeCode: string = watch("proposalDetails.financeType");
  const clientType = watch("customerDetails.clientType");

  const { data: documentTypes } = useGetDocumentTypes();
  const clientTypeName = useGetClientTypeName(clientType);
  const financeTypeName = useGetFinanceTypeName(financeCode);

  const documentSetupData = documentTypes?.filter(
    (document) =>
      document?.customer_type?.includes(clientTypeName) &&
      document?.finance_type?.includes(financeTypeName)
  );
  return (
    <>
      <CreditDocumentsWrap theme={theme}>
        <CreditDocumentTable
          setOpenDialog={setOpenDialog}
          setEditDocumentId={setEditDocumentId}
          showAddBtn={showAddBtn}
          setOpenViewDialog={setOpenViewDialog}
        />
      </CreditDocumentsWrap>
      <DocumentDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        editDocumentId={editDocumentId}
        setEditDocumentId={setEditDocumentId}
        setupData={documentSetupData}
      />
      <ViewDocumentDialog
        openDialog={openViewDialog}
        setOpenDialog={setOpenViewDialog}
        editDocumentId={editDocumentId}
        setEditDocumentId={setEditDocumentId}
      ></ViewDocumentDialog>
    </>
  );
};

export default CreditDocuments;
