import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";

export const BarChartWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.bar-chart-wrap": {
    width: "100%",
    height: "100%",
    ".recharts-wrapper": {
      ".recharts-cartesian-grid": {
        ".recharts-cartesian-grid-horizontal": {
          line: {
            "&:first-child": {
              stroke: "transparent",
            },
          },
        },
      },
      ".recharts-surface": {
        ".recharts-rectangle": {
          "&.recharts-tooltip-cursor": {
            fill: "#F8F8F8",
          },
        },
      },
    },
    ".recharts-layer": {
      "&.recharts-xAxis": {
        ".recharts-cartesian-axis-line": {
          stroke: theme.palette.grey[900],
        },
      },
      "&.recharts-yAxis": {
        ".recharts-cartesian-axis-line": {
          stroke: "transparent",
        },
      },
      ".recharts-cartesian-axis-tick": {
        ".recharts-cartesian-axis-tick-line": {
          display: "none",
        },
        ".recharts-text": {
          fontSize: theme.typography.body2.fontSize,
          fill: theme.palette.grey[900],
        },
      },
    },
  },
}));

export const CustomTooltipWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.custom-tooltip-wrap": {
    backgroundColor: theme.palette.grey[900],
    color: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
    padding: "2px 8px",
    textAlign: "left",
    ".ledger-indicator": {
      backgroundColor: theme.palette.primary.main,
      width: 10,
      height: 10,
      borderRadius: "50%",
    },
  },
}));
