import {
  LayoutWithSideNavComponent,
  ComparisonTool,
  Snackbar,
  ConfigurationWrapper as ConfigurationContextProvider,
  QuotationComparisonPageHeader,
} from "@components";
import { useTheme } from "@mui/material";
import { ComparisonPageContentWrap } from "./quote-comparison.style";
import { useState } from "react";
import { EvaluationExternalParams } from "@ntpkunity/controls-common";
import { useGetCompanyByTenantId } from "@hooks/queries";

export const QuoteComparisonPage = () => {
  const theme = useTheme();
  const { data: companyInfo } = useGetCompanyByTenantId();
  const [evaluationParams, setEvaluationParams] =
    useState<EvaluationExternalParams>({
      asset_category: "",
      asset_type: "",
      company_code: companyInfo.company_code,
      customer_type: "",
    });

  return (
    <LayoutWithSideNavComponent theme={theme}>
      <ComparisonPageContentWrap
        theme={theme}
        className="comparison-page-content-wrap"
      >
        <ConfigurationContextProvider>
          <ComparisonTool
            renderHeader={({ selectedQuotationRef, disableBtns }) => (
              <QuotationComparisonPageHeader
                selectedQuotationRef={selectedQuotationRef}
                disableBtns={disableBtns}
                setEvaluationParams={setEvaluationParams}
              />
            )}
            evaluationParams={evaluationParams}
          />
          <Snackbar />
        </ConfigurationContextProvider>
      </ComparisonPageContentWrap>
    </LayoutWithSideNavComponent>
  );
};
