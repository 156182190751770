import { useQuery } from "react-query";
import { QUERY_KEYS } from "@helpers/query";
import { getFinanceTypes } from "@services/index.service";
import { FinanceTypeSetupRes } from "@_types/setups";
import useGetCompanyByTenantId from "./useGetCompanyProfile";

const useGetFinanceTypes = () => {
  const { data: companyInfo } = useGetCompanyByTenantId();
  const companyId = companyInfo?.id;

  return useQuery<number, unknown, FinanceTypeSetupRes>({
    queryKey: [QUERY_KEYS.GET_FINANCE_TYPES, companyId],
    queryFn: () => getFinanceTypes(companyId),
    enabled: !!companyId,
  });
};

export default useGetFinanceTypes;
