import { TableCell, TableRow, useTheme } from "@mui/material";
import {
  Box,
  Button,
  DataTable,
  Icon,
  Input,
  Pagination,
  Typography,
} from "@ntpkunity/controls";
import { DataTableWrap } from "@styles/data-table-wrap";
import DirectorTableRow from "./director-table-row.component";

export const DirectorTable = () => {
  const theme = useTheme();

  return (
    <>
      <DataTableWrap theme={theme} className="table-pagination">
        <Box theme={theme} className="scroll">
          <Box className="scroll-hide spr-border" theme={theme} />
        </Box>
        <DataTable
          theme={theme}
          theadChildren={
            <>
              <TableRow className="has-sorting">
                <TableCell>
                  Full Name
                  <span className="sort-action">
                    <Button
                      theme={theme}
                      onClick={() => {}}
                      iconText={<Icon name="AscDescIcon" />}
                    />
                  </span>
                </TableCell>
                <TableCell>
                  Email
                  <span className="sort-action">
                    <Button
                      theme={theme}
                      onClick={() => {}}
                      iconText={<Icon name="AscDescIcon" />}
                    />
                  </span>
                </TableCell>
                <TableCell>
                  Contact Number
                  <span className="sort-action">
                    <Button
                      theme={theme}
                      onClick={() => {}}
                      iconText={<Icon name="AscDescIcon" />}
                    />
                  </span>
                </TableCell>
                <TableCell>
                  Address
                  <span className="sort-action">
                    <Button
                      theme={theme}
                      onClick={() => {}}
                      iconText={<Icon name="AscDescIcon" />}
                    />
                  </span>
                </TableCell>
                <TableCell className="action-cell fixed-cell" />
              </TableRow>
              <TableRow className="filters-row">
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      theme={theme}
                      fullWidth={true}
                      type={""}
                      startAdornment={""}
                      endAdornment={""}
                      id={""}
                      placeholder="Search"
                    ></Input>
                    <Button
                      defaultBtn
                      theme={theme}
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      theme={theme}
                      fullWidth={true}
                      type={""}
                      startAdornment={""}
                      endAdornment={""}
                      id={""}
                      placeholder="Search"
                    ></Input>
                    <Button
                      defaultBtn
                      theme={theme}
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      theme={theme}
                      fullWidth={true}
                      type={""}
                      startAdornment={""}
                      endAdornment={""}
                      id={""}
                      placeholder="Search"
                    ></Input>
                    <Button
                      defaultBtn
                      theme={theme}
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      theme={theme}
                      fullWidth={true}
                      type={""}
                      startAdornment={""}
                      endAdornment={""}
                      id={""}
                      placeholder="Search"
                    ></Input>
                    <Button
                      defaultBtn
                      theme={theme}
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>
                <TableCell className="action-cell fixed-cell" />
              </TableRow>
            </>
          }
          tbodyChildren={
            <>
              <DirectorTableRow />
              <DirectorTableRow />
              <DirectorTableRow />
              <DirectorTableRow />
            </>
          }
        />
        <Pagination theme={theme} />
      </DataTableWrap>
    </>
  );
};
