import { useQuery } from "react-query";
import { QUERY_KEYS } from "@helpers/query";
import { getAllDealers } from "@services/index.service";
import useGetCompanyByTenantId from "./useGetCompanyProfile";
import { Dealer } from "@_types/company";

const useGetAllActiveDealers = () => {
  const { data: companyInfo } = useGetCompanyByTenantId();
  const companyId = companyInfo?.id;

  return useQuery<number, unknown, Dealer[]>({
    queryKey: [QUERY_KEYS.GET_ALL_DEALERS, companyId],
    queryFn: () => getAllDealers(companyId),
    enabled: !!companyId,
  });
};

export default useGetAllActiveDealers;
