import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";

export const FullWindowDialogContentWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.full-window-dialog-content-wrap": {
    ".page-content": {
      ".comparison-btn": {
        margin: 0,
        backgroundColor: "#F5F5F7",
        padding: 24,
        borderRadius: 8,
        marginBottom: 16,
      },
      ".results-btn": {
        margin: 0,
        backgroundColor: "#F5F5F7",
        padding: 24,
        borderRadius: 8,
        marginBottom: 96,
      },
      ".cp": { cursor: "pointer" },
      ".quotation-tool-container": {
        margin: 0,
        ".comparison-container": {
          marginTop: 0,
        },
        ".quotation-tool-wrap": {
          ".quotation-box": {
            "&.fields-wrap": {
              height: "calc(100vh - 170px)",
            },
          },
        },
      },
      ".comparison-box": {
        "&::before": {
          left: -8,
        },
      },
      ".add-new": {
        height: "calc(100vh - 162px)",
      },
      "&.has-footer": {
        ".footer-wrap": {
          width: "calc(100% - 32px)",
          left: 16,
          marginLeft: 0,
          ".back-btn": {
            ".btn": {
              "&.with-icon": {
                ".MuiButton-iconSizeMedium": {
                  width: 16,
                  height: 16,
                },
              },
            },
          },
        },
      },
    },
  },
}));
