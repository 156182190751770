import { useTheme } from "@mui/material";
import { Box, Button, Grid, Input, Typography } from "@ntpkunity/controls";
import { CompanyNameWrap } from "./companies-by-name.style";
import {
  CompaniesByNameResponse,
  CompanyNameForm,
  CompanySearchReqParams,
} from "@_types";
import { useForm, Controller } from "react-hook-form";
import { CompanyInfo } from "./company-info/company-info.component";

type CompaniesByNameProps = {
  companiesLoading: boolean;
  errorInCompanies: boolean;
  searchParams: CompanySearchReqParams;
  setSearchParams: React.Dispatch<React.SetStateAction<CompanySearchReqParams>>;
  companies: CompaniesByNameResponse[];
  errorMessage: string | undefined;
  selectedCompany: CompaniesByNameResponse;
  setSelectedCompany: React.Dispatch<
    React.SetStateAction<CompaniesByNameResponse>
  >;
};

export const CompaniesByName = ({
  companiesLoading,
  errorInCompanies,
  searchParams,
  setSearchParams,
  companies,
  errorMessage,
  selectedCompany,
  setSelectedCompany,
}: CompaniesByNameProps) => {
  const theme = useTheme();
  const { control, getValues } = useForm<CompanyNameForm>();

  const handleFetchCompanies = () => {
    setSearchParams({ ...searchParams, company_name: getValues().companyName });
  };

  return (
    <CompanyNameWrap theme={theme} className="company-name-wrap">
      <Grid theme={theme} container item spacing={2}>
        <Grid theme={theme} item xs={9}>
          <Controller
            name="companyName"
            control={control}
            render={({ field }) => {
              return (
                <Input
                  theme={theme}
                  type="text"
                  placeholder="Type Here..."
                  label="Company Name"
                  fullWidth
                  {...field}
                />
              );
            }}
          />
        </Grid>
        <Grid theme={theme} item xs={3}>
          <Button
            theme={theme}
            text="Lookup"
            type="button"
            fullWidth
            primary
            onClick={handleFetchCompanies}
          />
        </Grid>
      </Grid>
      {!!searchParams.company_name && (
        <Box theme={theme} className="list-wrap">
          <Typography theme={theme} component={"p"} variant="subtitle1" mt={3}>
            Select Company
          </Typography>
          {errorInCompanies || companiesLoading || !companies.length ? (
            <Typography
              theme={theme}
              variant="body2"
              component="span"
              display="block"
              textAlign="center"
            >
              {errorInCompanies
                ? errorMessage ||
                  "Could not find any company with the given name"
                : companiesLoading
                ? "Searching Companies..."
                : "No Companies found."}
            </Typography>
          ) : (
            companies.map((company) => (
              <CompanyInfo
                key={company.company_number}
                company={company}
                selectedCompany={selectedCompany}
                setSelectedCompany={setSelectedCompany}
              />
            ))
          )}
        </Box>
      )}
    </CompanyNameWrap>
  );
};
