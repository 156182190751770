import { Stack } from "@components";
import { useTheme } from "@mui/material";
import { Accordion } from "@ntpkunity/controls";
import { DirectorDetailsWrap } from "./director-details.style";
import { DirectorTable } from "./director-table/director-table.component";
import { DirectorDialog } from "./director-dialog/director-dialog.component";
import { useState } from "react";
import { ClientTypes } from "@helpers/enum";
import { DirectorDetailsProps } from "./director-details.props";
import { useFormContext } from "react-hook-form";
import { TProposalForm } from "@_types";
import { useGetClientTypeName } from "@hooks/useSetupNames";

export const DirectorsDetails = ({
  showAddBtn = true,
}: DirectorDetailsProps) => {
  const theme = useTheme();
  const [openDialog, setOpenDialog] = useState(false);
  const [editDirectorId, setEditDirectorId] = useState<string | null>(null);

  const { watch } = useFormContext<TProposalForm>();
  const clientTypeCode = watch("customerDetails.clientType");
  const clientTypeName = useGetClientTypeName(clientTypeCode);

  const title = `${
    clientTypeName === ClientTypes.PARTNERSHIP ? "Partners" : "Directors"
  } Details`;

  return (
    <>
      <DirectorDetailsWrap className="director-details-wrap" theme={theme}>
        <Stack
          paddingXs={3}
          paddingMd={3}
          marginTopMd={2}
          marginTopXs={2}
          className="collapse-wrap"
        >
          <Accordion
            theme={theme}
            items={[
              {
                content: (
                  <DirectorTable
                    setOpenDialog={setOpenDialog}
                    setEditDirectorId={setEditDirectorId}
                    showAddBtn={showAddBtn}
                  />
                ),
                title: title,
                key: "string",
                isExpanded: false,
                id: "string",
                ariaControls: "string",
              },
            ]}
          />
        </Stack>
      </DirectorDetailsWrap>
      <DirectorDialog
        open={openDialog}
        setOpenDialog={setOpenDialog}
        editDirectorId={editDirectorId}
        setEditDirectorId={setEditDirectorId}
      />
    </>
  );
};
