import { styled } from "@mui/material/styles";

export const PaginationWrap = styled("div")(({ theme }) => ({
  "&.pagination-wrap": {
    ".MuiTablePagination-root": {
      marginTop: "-4px",
      paddingTop: "4px",
      borderColor: theme.palette.grey[100],
      color: theme.palette.grey[600],
      fontSize: theme.typography.htmlFontSize,
      fontFamily: theme.typography.fontFamily,
    },
    ".MuiTablePagination-select:focus": {
      backgroundColor: "transparent",
    },
    ".MuiTablePagination-selectIcon": {
      top: 2,
      left: 32,
    },
  },
}));
