import "react";
import { Accordion, Box, Button, Icon } from "@ntpkunity/controls";
import { useTheme } from "@mui/material";
import { AdditionalDetailsListWrap } from "./compliance-document.style";
import { Stack } from "@components/stack";
import ComplianceDocumentContent from "./compliance-document-content.component";
import { Template } from "@_types/setups";
import { usePreviewDocs } from "@hooks/mutations";

const CompliacneDocumentList = ({ customerDetails, complianceDocuments }) => {
  const theme = useTheme();
  const { mutateAsync: previewDocs } = usePreviewDocs();

  const handlePreview = async (key: string) => {
    const templateUrl = await previewDocs(key);
    window.open(templateUrl, "_blank");
  };

  return (
    complianceDocuments?.length > 0 && (
      <AdditionalDetailsListWrap
        theme={theme}
        className="additional-details-list-wrap"
      >
        {complianceDocuments?.flatMap((item) =>
          item?.templates?.map((template: Template, index: number) => (
            <Stack
              key={index} // Ensure each Stack has a unique key
              paddingMd={3}
              paddingXs={3}
              marginTopMd={2}
              marginTopXs={2}
              title={
                <Box
                  theme={theme}
                  display={"flex"}
                  alignItems={"center"}
                  gap={2}
                >
                  <span className="title-with-icon">
                    <Icon name="DocumentIcon" />
                  </span>
                  {template.template_name.replace(".docx", "")}
                </Box>
              }
              actionArea={
                <Button
                  theme={theme}
                  defaultBtn
                  iconText={<Icon name="IcView" />}
                  onClick={() => handlePreview(template.template_name)}
                />
              }
            ></Stack>
          ))
        )}
      </AdditionalDetailsListWrap>
    )
  );
};

export default CompliacneDocumentList;
