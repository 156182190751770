import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { StyledEngineProvider } from "@mui/material";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { Router } from "@router";
import { BROKER_APP_THEME, BROKER_APP_THEME_WRAP } from "styles";
import { SnackbarContextProvider } from "@contexts/snackbar";
import {
  LoginValidatorComponent,
  UMSConfigurationComponent,
} from "@ntpkunity/controls-ums";
import { useEffect, useState } from "react";
import { setFavicon, setLogo, setStyleSheet } from "@helpers/theme";
import {
  useGetCompanyByTenantId,
  useGetDealers,
  useGetDocumentTypes,
  useGetProductTypes,
  useGetThemebyEmail,
} from "@hooks/queries";
import { useAssetSetups, useClientTypesSetup } from "@hooks/proposal";
import { Button, CircleLoader, Icon, PageAlert } from "@ntpkunity/controls";
import { getEntityTypeDealer } from "@helpers/utils";
import { useFilteredFinanceTypes } from "@hooks";

export default function Root(props) {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: Infinity,
        retry: 1,
      },
    },
  });
  const url = process.env.UNITY_FE_BASE_URL;
  return (
    <UMSConfigurationComponent>
      <LoginValidatorComponent theme={BROKER_APP_THEME} url={url}>
        <QueryClientProvider client={queryClient}>
          <App {...props} />
        </QueryClientProvider>
      </LoginValidatorComponent>
    </UMSConfigurationComponent>
  );
}
const App = () => {
  const [userEmail, setUserEmail] = useState<string>("");
  const {
    data: companyInfo,
    isLoading: companyInfoLoading,
    isError: isErrorInCompanyInfo,
  } = useGetCompanyByTenantId();
  const { data: dealerAssociation } = useGetDealers(getEntityTypeDealer());
  const { data: theme } = useGetThemebyEmail(userEmail);
  document.title = "Link";

  // *** Setups calls to load setups on root level
  useAssetSetups();
  useGetProductTypes();
  useFilteredFinanceTypes();
  useClientTypesSetup();
  useGetDocumentTypes();
  // ***

  useEffect(() => {
    if (companyInfo) {
      setUserEmail(companyInfo?.email);
    }
  }, [companyInfo]);

  useEffect(() => {
    if (theme) {
      setStyleSheet(theme?.stylesheet);
      setFavicon(theme?.favicon?.location);
      setLogo(theme?.logo?.location);
    }
  }, [theme]);

  return (
    <StyledEngineProvider injectFirst>
      <MuiThemeProvider theme={BROKER_APP_THEME}>
        <BROKER_APP_THEME_WRAP
          className="global-theme-wrap"
          theme={BROKER_APP_THEME}
        >
          <SnackbarContextProvider>
            {companyInfoLoading ? (
              <CircleLoader theme={BROKER_APP_THEME} />
            ) : isErrorInCompanyInfo ? (
              <PageAlert
                theme={BROKER_APP_THEME}
                icon="DialogAlertIcon"
                title="Oops! Unexpected Error occurred."
                variant="full-page"
                message="We encountered an unexpected error. Please refresh the page."
                actionArea={
                  <>
                    <Button
                      theme={BROKER_APP_THEME}
                      fullWidth
                      danger
                      startIcon={<Icon name="ResetIcon" />}
                      text="Refresh Page"
                      onClick={() => window.location.reload()}
                    />
                  </>
                }
              />
            ) : (
              <BrowserRouter>
                <Router />
              </BrowserRouter>
            )}
          </SnackbarContextProvider>
        </BROKER_APP_THEME_WRAP>
      </MuiThemeProvider>
    </StyledEngineProvider>
  );
};
