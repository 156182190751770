import { useTheme } from "@mui/material";
import { FormWrap } from "./sign-in-form.style";
import { Box, Button, Grid, Input, Typography } from "@ntpkunity/controls";
import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from "@helpers";
import { ReactNode } from "react";

export const SignInForm = ({ title }: { title?: string | ReactNode }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <FormWrap theme={theme} className="form-wrap">
      <Typography
        theme={theme}
        variant="h2"
        component={"h2"}
        className="text-h2"
      >
        Welcome to <span className="text-primary">{title}</span>
      </Typography>
      <Box theme={theme} className="form-area" mt={3}>
        <Grid theme={theme} container item spacing={3}>
          <Grid theme={theme} item xs={12}>
            <Input
              theme={theme}
              placeholder="Type here..."
              label="Email Address"
              type="text"
              dynamic={false}
              fullWidth
            />
          </Grid>
          <Grid theme={theme} item xs={12}>
            <Input
              theme={theme}
              placeholder="Type here..."
              label="Password"
              type="password"
              dynamic={false}
              fullWidth
            />
            <a href="" className="fp-link text-secondary link">
              <Typography
                theme={theme}
                component={"small"}
                variant="caption"
                className="text-secondary"
                display={"block"}
                mt={0.5}
                ml={2}
              >
                Forgot your password?
              </Typography>
            </a>
          </Grid>
        </Grid>
        <Box theme={theme} className="form-cta" mt={4}>
          <Button
            theme={theme}
            primary
            fullWidth
            text="Login"
            onClick={() => {
              navigate(APP_ROUTES.DASHBOARD);
            }}
            type="button"
          />
          <Typography
            theme={theme}
            component={"span"}
            variant="body2"
            className="text-muted"
            display={"none"}
            mt={4}
          >
            New to Appex Now?{" "}
            <a href="#" className="text-primary link">
              Create Account
            </a>
          </Typography>
        </Box>
      </Box>
    </FormWrap>
  );
};
