import { PageHeader } from "@components";
import { useTheme } from "@mui/material";
import { PageWrap } from "./customer-proposal-page.style";
import { Box, Button } from "@ntpkunity/controls";
import { CustomerProposal } from "@components/customer-proposal";
import Logo from "../../public/assets/images/NatWest-logo-full.svg";

export const CustomerProposalPage = () => {
  const theme = useTheme();

  return (
    <PageWrap theme={theme} className="page-wrap">
      <Box theme={theme} className="main-header">
        <PageHeader
          title={
            <>
              <img src={Logo} alt="Logo" />
            </>
          }
        />
      </Box>

      <Box theme={theme} className="page-content" pt={3} pb={3}>
        <PageHeader
          title="Sample Proposal Name"
          actionArea={
            <>
              <Button theme={theme} primary text={"Submit"} />
            </>
          }
        />
        <Box theme={theme} mt={2}>
          <CustomerProposal />
        </Box>
      </Box>
    </PageWrap>
  );
};
