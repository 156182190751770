import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";

export const SettingDialogContent = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.settings-dialog-content": {
    ".setting-item": {
      marginTop: 24,
      "&:first-child": {
        marginTop: 0,
      },
      [theme.breakpoints.down("md")]: {
        marginTop: 32,
      },
    },
    ".dialog-head-wrap": {
      display: "flex",
      alignItems: "center",
      gap: 24,
      flexWrap: "wrap",
      padding: "8px 24px",
      marginBottom: 24,
      ".action-area": {
        flexShrink: 0,
        ".dropown-item": {
          display: "flex",
          alignItems: "center",
          gap: 4,
          padding: "8px 12px",
          backgroundColor: "#F5F5F7",
          borderRadius: 8,
          ".text-muted": {
            lineHeight: "normal",
            color: theme.palette.grey[600],
            [theme.breakpoints.down("md")]: {
              flexShrink: 0,
            },
          },
          ".u-form-group": {
            ".MuiFormControl-root": {
              height: 24,
            },
            ".u-form-control": {
              "&.u-select": {
                height: 24,
                ".MuiInputBase-input": {
                  padding: "0px 22px 0px 0",
                  fontWeight: theme.customVariables.fontWeightSemiBold,
                  fontSize: theme.typography.body2.fontSize,
                  lineHeight: theme.typography.body1.lineHeight,
                },
                ".MuiSelect-icon": {
                  width: 18,
                  height: 18,
                  right: 0,
                },
                fieldset: {
                  border: "none",
                },
              },
            },
          },
        },
      },
    },
  },
}));
