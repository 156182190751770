import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";

export const PageWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.page-wrap": {
    ".page-header-wrap": {
      borderBottom: "none",
    },
    ".main-header": {
      ".page-header-wrap": {
        ".title-area": {
          ".page-title": {
            lineHeight: theme.typography.body1.lineHeight,
            width: "100%",
          },
          img: {
            maxHeight: 40,
          },
        },
        [theme.breakpoints.down("md")]: {
          top: 0,
        },
      },
    },
    ".page-content": {
      paddingLeft: 40,
      paddingRight: 40,
      ".page-header-wrap": {
        borderRadius: 8,
        [theme.breakpoints.down("md")]: {
          top: 75,
          left: 0,
          right: 0,
          borderRadius: 0,
          borderTop: "1px solid" + theme.palette.divider,
        },
      },
      [theme.breakpoints.down("md")]: {
        padding: "222px 24px 24px 24px",
      },
    },
  },
}));
