import { LOCAL_STORAGE_KEYS } from "@helpers/const";
import { InternalAxiosRequestConfig } from "axios";

export const requestIntercepter = (config: InternalAxiosRequestConfig<any>) => {
  // Logic to add the bearer and x-api-key
  switch (config.baseURL) {
    default: {
      config.headers["Authorization"] =
        `Bearer ${
          JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.SETTINGS))
            ?.access_token as string
        }` ?? "";
      break;
    }
  }
  return config;
};
