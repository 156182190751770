import { useState } from "react";
import { useTheme } from "@mui/material";
import { DiaryContent } from "./diary-dialog.style";
import { Stack, FullWindowDialog } from "@components";
import { Box, DuoTab, Grid, Icon } from "@ntpkunity/controls";
import {
  AddComments,
  AuditTrail,
  AuditTrailDialog,
  CommunicationHistory,
  CommunicationHistoryDialog,
  PreviousComments,
  PreviousCommentsDialog,
} from "@components/diary";
import { TCommentsDiaryDetails } from "@_types/forms/diaryComments";
import { saveDiaryComment } from "@services/dms.service";
import useGetDiaryComments from "@hooks/queries/useGetDiaryComments";
import { DiaryDialogData } from "@_types/proposal";

type DiaryDialogProps = {
  dialogData: DiaryDialogData;
  closeDialog: () => void;
  open: boolean;
};

export const DiaryDialog = ({
  open,
  closeDialog,
  dialogData,
}: DiaryDialogProps) => {
  if (!open) {
    return <></>;
  }
  const theme = useTheme();
  const [auditTrailDialog, setAuditTrailDialog] = useState(false);
  const [previousCommentsDialog, setPreviousCommentsDialog] = useState(false);
  const [communicationHistoryDialog, setcommunicationHistoryDialog] =
    useState(false);

  const { data: comments, refetch } = useGetDiaryComments(
    dialogData?.proposalId
  );
  const handleAddComment = async (proposalComment: TCommentsDiaryDetails) => {
    await saveDiaryComment({
      ...proposalComment,
      order_reference_id: dialogData?.proposalId,
    });
    await refetch();
  };

  const sortedComments = comments?.sort(
    (a: TCommentsDiaryDetails, b: TCommentsDiaryDetails) => {
      return (
        new Date(a?.created_at)?.getTime() - new Date(b?.created_at)?.getTime()
      );
    }
  );

  return (
    <FullWindowDialog open={open} onClose={closeDialog}>
      <DiaryContent theme={theme} className="diary-content">
        <Grid theme={theme} container spacing={2} alignItems={"stretch"}>
          <Grid theme={theme} item xl={9} lg={8} md={7} sm={12} xs={12}>
            <Stack paddingMd={3} paddingXs={3} bgLight>
              <AddComments onAddComment={handleAddComment} />
              <Box theme={theme} display={{ xs: "none", md: "block" }}>
                <Stack
                  paddingMd={3}
                  paddingXs={3}
                  marginTopMd={3}
                  marginTopXs={3}
                  className="tabs-area"
                >
                  <DuoTab
                    theme={theme}
                    varient="underline"
                    items={[
                      {
                        title: "Previous Comments",
                        content: <PreviousComments comments={sortedComments} />,
                      },
                    ]}
                    defaultTabIndex={0}
                  />
                </Stack>
              </Box>
              <Box theme={theme} display={{ xs: "block", md: "none" }}>
                <Box theme={theme} mt={2}>
                  <Stack
                    className="cp"
                    paddingMd={3}
                    paddingXs={3}
                    title="Audit Trail"
                    onClick={() => setAuditTrailDialog(true)}
                    actionArea={<Icon name="IconRightArrow" />}
                  />
                </Box>
                <Box theme={theme} mt={2}>
                  <Stack
                    className="cp"
                    paddingMd={3}
                    paddingXs={3}
                    title="Previous Comments"
                    onClick={() => setPreviousCommentsDialog(true)}
                    actionArea={<Icon name="IconRightArrow" />}
                  />
                </Box>
                <Box theme={theme} mt={2}>
                  <Stack
                    className="cp"
                    paddingMd={3}
                    paddingXs={3}
                    title="Communication history"
                    onClick={() => setcommunicationHistoryDialog(true)}
                    actionArea={<Icon name="IconRightArrow" />}
                  />
                </Box>
              </Box>
            </Stack>
          </Grid>
          <Grid theme={theme} item xl={3} lg={4} md={5} sm={12} xs={12}>
            <Box theme={theme} display={{ xs: "none", md: "block" }}>
              <AuditTrail proposalId={dialogData?.proposalId} />
            </Box>
          </Grid>
        </Grid>
      </DiaryContent>
      {auditTrailDialog && (
        <AuditTrailDialog
          open={auditTrailDialog}
          onClose={() => setAuditTrailDialog(false)}
          proposalId={dialogData?.proposalId}
        />
      )}
      {previousCommentsDialog && (
        <PreviousCommentsDialog
          open={previousCommentsDialog}
          onClose={() => setPreviousCommentsDialog(false)}
          comments={comments}
        />
      )}
      {communicationHistoryDialog && (
        <CommunicationHistoryDialog
          open={communicationHistoryDialog}
          onClose={() => setcommunicationHistoryDialog(false)}
        />
      )}
    </FullWindowDialog>
  );
};
