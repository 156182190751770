import { useProposalFieldArrays } from "@contexts/proposal-form";
import { FormProvider, useForm } from "react-hook-form";
import { TAssetDetails } from "@_types";
import { AssetDetailsForm } from "@components/proposal/asset-details";
import { EditAssetFormProps } from "./edit-asset-form.props";
import {
  QuotationForm,
  useQuotationFormContext,
} from "@ntpkunity/controls-common";
import { useSumOfAssetCost, useSumOfRvBalloonAmount } from "@hooks/proposal";
import { useSnackbarContext } from "@contexts/snackbar";

const EditAssetForm = ({ editAssetId, setEditAssetId }: EditAssetFormProps) => {
  const {
    assetMethods: { update, fields: assets },
  } = useProposalFieldArrays();
  const { setValue: setQuotationFormValue } =
    useQuotationFormContext<QuotationForm>();
  const { setSnackbar } = useSnackbarContext();
  const sumOfAssetCost = useSumOfAssetCost();
  const sumOfRvBalloonAmount = useSumOfRvBalloonAmount();
  const assetToUpdate = assets.find((asset) => asset.id === editAssetId);
  const formMethods = useForm<TAssetDetails>({
    defaultValues: { ...assetToUpdate },
  });
  const { watch } = formMethods;
  const totalCost = watch("totalCost");
  const totalRvBalloonAmount = watch("totalRvBalloonAmount");

  const handleSaveAsset = () => {
    const indexOfAssetToEdit = assets.findIndex(
      (director) => director.id === editAssetId
    );
    if (indexOfAssetToEdit === -1) {
      setSnackbar(
        {
          message: "The record is no longer available",
          open: true,
        },
        3000
      );
    } else {
      update(indexOfAssetToEdit, {
        ...formMethods.getValues(),
        totalCost: totalCost,
      });
      const oldAssetCost = assets[indexOfAssetToEdit].totalCost;
      const oldRvBalloonAmount =
        assets[indexOfAssetToEdit].totalRvBalloonAmount;
      const updatedSumOfAssetCost = sumOfAssetCost - oldAssetCost + totalCost;
      const updatedSumOfRvBalloonAmount =
        sumOfRvBalloonAmount - oldRvBalloonAmount + totalRvBalloonAmount;
      setQuotationFormValue("assetCost", updatedSumOfAssetCost);
      setQuotationFormValue("balloonPayment", updatedSumOfRvBalloonAmount);
      setSnackbar({ open: true, message: "Asset Saved Successfully." }, 2000);
    }
    formMethods.reset();
    setEditAssetId(null);
  };

  return (
    <FormProvider {...formMethods}>
      <AssetDetailsForm
        handleSaveAsset={handleSaveAsset}
        isEditMode
        setEditAssetId={setEditAssetId}
      />
    </FormProvider>
  );
};

export default EditAssetForm;
