import { useTheme } from "@mui/material";
import { SettingDialogContent } from "./setting-dialog.style";
import {
  Box,
  Button,
  Dialog,
  Grid,
  Input,
  Select,
  Switch,
  Typography,
} from "@ntpkunity/controls";
import { Controller, useFormContext } from "react-hook-form";
import { formatPermissionName, PERMISSION_OPTIONS } from "@helpers/const";
import { useUpdateUmsPermissionConfigurations } from "@hooks/mutations/useSaveConfigurations";
import { useMemo, useState } from "react";
import { Stack } from "@components/stack";
import { useSnackbarContext } from "@contexts/snackbar";
import { useReloadAllPermissions } from "@ntpkunity/controls-ums";
import { getUserSettings } from "@helpers/utils";
import { useQueryClient } from "react-query";
import { QUERY_KEYS } from "@helpers/query";

export const SettingsDialog = ({
  open,
  onClose,
  financeType,
  umsPermissions,
}) => {
  const theme = useTheme();
  const { control, getValues, setValue, watch } = useFormContext();
  const { setSnackbar } = useSnackbarContext();
  const userRoleName = getUserSettings()?.role?.name;
  const queryClient = useQueryClient();

  const groupOptions = useMemo(() => {
    const uniqueGroups = new Map();
    umsPermissions?.forEach((permission) => {
      if (!uniqueGroups.has(permission?.group_name)) {
        uniqueGroups.set(permission?.group_name, permission?.group_id);
      }
    });
    return Array.from(uniqueGroups?.entries())?.map(([label, value]) => ({
      text: label,
      value: value,
    }));
  }, [umsPermissions]);
  const [financeTypePermissions, setFinanceTypePermissions] = useState(
    getValues(`${financeType}.permissions`)?.filter(
      (permission) =>
        permission?.group_id ==
        (getValues(`${financeType}.group_id`) ??
          groupOptions.find((option) => option.text == userRoleName)?.value)
    )
  );
  const { mutate: permissionsConfiguration, isLoading: savingPermissions } =
    useUpdateUmsPermissionConfigurations();
  const reloadPermissions = useReloadAllPermissions();

  const handleSubmit = () => {
    const group_id = getValues(`${financeType}.group_id`);
    const permissions = getValues(`${financeType}.permissions`).filter(
      (permission) => permission?.group_id == group_id
    );
    const payload = permissions?.map((permission) => ({
      id: permission?.id,
      name: permission?.name,
      default_value: permission?.default_value,
      is_view: permission?.is_view,
      is_enabled: permission?.is_enabled,
      is_mandatory: permission?.is_mandatory,
    }));

    permissionsConfiguration(
      { payload, group_id },
      {
        async onSuccess() {
          setSnackbar(
            {
              message: "Settings successfully saved!",
              open: true,
              variant: null,
            },
            10000
          );
          onClose();
          queryClient.invalidateQueries([QUERY_KEYS.GET_PRODUCT_ID]);
          await reloadPermissions();
        },
        onError() {
          setSnackbar(
            {
              message: "Error occurred while saving settings!",
              open: true,
              variant: "error",
            },
            10000
          );
          onClose();
        },
      }
    );
  };
  return (
    <Dialog
      theme={theme}
      variant="full-window"
      onCloseDialog={onClose}
      open={open}
      customFooter={
        <Button
          theme={theme}
          primary
          text="Save Changes"
          className="w-m-100"
          disabled={savingPermissions}
          onClick={() => {
            handleSubmit();
          }}
          type="button"
        />
      }
    >
      <SettingDialogContent theme={theme} className="settings-dialog-content">
        <Box theme={theme} className="dialog-head-wrap">
          <Typography theme={theme} variant="h3" component={"h3"}>
            Select Group
          </Typography>
          <Box theme={theme} className="action-area">
            <Box className="dropown-item" theme={theme}>
              <Typography
                variant="body2"
                component={"span"}
                display={"block"}
                className="text-muted"
                theme={theme}
              >
                Roles:
              </Typography>
              <Controller
                name={`${financeType}.group_id`}
                control={control}
                defaultValue={
                  groupOptions.find((option) => option.text == userRoleName)
                    ?.value
                }
                render={({ field }) => (
                  <Select
                    theme={theme}
                    fullWidth
                    {...field}
                    disablePortal={false}
                    placeholder="Select an option..."
                    value={field.value}
                    onChange={(e) => {
                      field.onChange(e.target.value);
                      const groupName = groupOptions.find(
                        (option) => option.value == e.target.value
                      )?.text;
                      setFinanceTypePermissions(
                        getValues(`${financeType}.permissions`).filter(
                          (permission) => permission?.group_name == groupName
                        )
                      );
                    }}
                    defaultValue={
                      groupOptions.find((option) => option.text == userRoleName)
                        ?.value
                    }
                    items={groupOptions}
                  />
                )}
              />
            </Box>
          </Box>
        </Box>
        <Stack paddingXs={3} paddingMd={3} bgLight>
          <Stack paddingXs={3} paddingMd={3}>
            {financeTypePermissions?.map((permission, index) => {
              const currentRoleIndex = getValues(
                `${financeType}.permissions`
              ).findIndex(
                (groupPermission) =>
                  groupPermission.name == permission.name &&
                  groupPermission.group_id ==
                    (getValues(`${financeType}.group_id`) ??
                      groupOptions.find((option) => option.text == userRoleName)
                        ?.value)
              );
              return (
                <Box theme={theme} className="setting-item" key={index}>
                  <Grid
                    theme={theme}
                    container
                    item
                    columnSpacing={{ xs: 2, md: 5 }}
                    rowSpacing={2}
                    alignItems={"center"}
                  >
                    <Grid theme={theme} item xs={12} md={3} lg={3} xl={3}>
                      <Controller
                        name={`${financeType}.permissions.${currentRoleIndex}.name`}
                        control={control}
                        defaultValue={permission.name}
                        render={() => (
                          <Typography
                            theme={theme}
                            component={"p"}
                            variant="subtitle1"
                          >
                            {formatPermissionName(permission?.name)}
                          </Typography>
                        )}
                      />
                    </Grid>
                    <Grid theme={theme} item xs={12} md={4} lg={5}>
                      <Controller
                        name={`${financeType}.permissions.${currentRoleIndex}.default_value`}
                        control={control}
                        render={({ field }) => {
                          return permission.permission_type === "text" ? (
                            <Input
                              theme={theme}
                              fullWidth
                              type="text"
                              {...field}
                              placeholder="Type here..."
                              label="Default Value"
                              value={permission.default_value}
                              disabled={
                                permission.default_value === "Output Value"
                                  ? true
                                  : false
                              }
                            />
                          ) : (
                            <Select
                              theme={theme}
                              fullWidth
                              {...field}
                              placeholder="Select an option..."
                              label="Options"
                              value={permission.default_value ?? []}
                              onChange={(e) => {
                                field.onChange(e.target.value);
                              }}
                              items={
                                PERMISSION_OPTIONS[
                                  formatPermissionName(permission?.name)
                                ]
                              }
                            />
                          );
                        }}
                      />
                    </Grid>
                    <Grid theme={theme} item xs={12} md={5} lg={4}>
                      <Grid
                        theme={theme}
                        container
                        item
                        columnSpacing={{ xs: 3, md: 5 }}
                        rowSpacing={2}
                        alignItems={"center"}
                      >
                        <Grid theme={theme} item xs={6} sm={4}>
                          <Controller
                            name={`${financeType}.permissions.${currentRoleIndex}.is_enabled`}
                            control={control}
                            render={({ field }) => (
                              <Switch
                                {...field}
                                theme={theme}
                                varient="basic"
                                switchEnabled={permission?.is_enabled === true}
                                label="Enabled"
                                disabled={watch(
                                  `${financeType}.permissions.${currentRoleIndex}.is_mandatory`
                                )}
                              />
                            )}
                          />
                        </Grid>
                        <Grid theme={theme} item xs={6} sm={4}>
                          <Controller
                            name={`${financeType}.permissions.${currentRoleIndex}.is_view`}
                            control={control}
                            defaultValue={permission?.is_view}
                            render={({ field }) => (
                              <Switch
                                theme={theme}
                                {...field}
                                varient="basic"
                                switchEnabled={permission.is_view === true}
                                label="Show"
                                disabled={watch(
                                  `${financeType}.permissions.${currentRoleIndex}.is_mandatory`
                                )}
                              />
                            )}
                          />
                        </Grid>
                        <Grid theme={theme} item xs={6} sm={4}>
                          <Controller
                            name={`${financeType}.permissions.${currentRoleIndex}.is_mandatory`}
                            control={control}
                            defaultValue={permission?.is_mandatory}
                            render={({ field }) => (
                              <Switch
                                theme={theme}
                                {...field}
                                varient="basic"
                                switchEnabled={
                                  permission.is_mandatory ? true : false
                                }
                                label="Mandatory"
                                onChange={(e) => {
                                  field.onChange(e.target.checked);
                                  if (e.target.checked) {
                                    setValue(
                                      `${financeType}.permissions.${currentRoleIndex}.is_enabled`,
                                      true
                                    );
                                    setValue(
                                      `${financeType}.permissions.${currentRoleIndex}.is_view`,
                                      true
                                    );
                                  }
                                }}
                              />
                            )}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              );
            })}
          </Stack>
        </Stack>
      </SettingDialogContent>
    </Dialog>
  );
};
