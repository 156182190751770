import { Box } from "@ntpkunity/controls";
import {
  QuotationForm,
  QuotationRatesContent,
  QuotationResultContent,
  QuotationToolWrap,
  useCalculationsContext,
  useQuotationFormContext,
} from "@ntpkunity/controls-common";
import { useTheme } from "@mui/material";
import { useGetFinanceTypeName } from "@hooks";
import { isDealerRole } from "@helpers/utils";

type QuotationResultsProps = {
  headerText: string;
  isSelected: boolean;
  handleSelectQuotation: () => void;
};

export const QuotationResults = ({
  headerText,
  isSelected,
  handleSelectQuotation,
}: QuotationResultsProps) => {
  const theme = useTheme();
  const { watch } = useQuotationFormContext<QuotationForm>();
  const { data: calculations, inProgress } = useCalculationsContext();
  const financeType = useGetFinanceTypeName(watch("financeType"));
  const _isDealerRole = isDealerRole();

  return (
    <Box theme={theme} className="comparison-box">
      <QuotationToolWrap theme={theme} className="quotation-tool-wrap">
        <Box theme={theme} pl={3} pt={3} pb={3}>
          <QuotationResultContent
            theme={theme}
            headerText={headerText}
            categoryText={financeType}
            buttonText={isSelected ? "Selected" : "Select"}
            isSelected={isSelected}
            onClick={handleSelectQuotation}
          />
          {!_isDealerRole && (
            <Box theme={theme} className="divider" pt={1} mt={1}>
              <QuotationRatesContent
                theme={theme}
                isLoading={inProgress}
                {...calculations.rates}
              />
            </Box>
          )}
        </Box>
      </QuotationToolWrap>
    </Box>
  );
};
