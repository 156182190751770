import { useQuery, UseQueryResult } from "react-query";
import { QUERY_KEYS } from "@helpers/query";
import { getSignatories } from "@services/dms.service";
import { TSortParams } from "@_types";

const useGetSignatories = <
  SearchParams extends { [key: string]: any },
  ReturnType extends { [key: string]: any }
>(
  searchParams: SearchParams,
  sortParams: TSortParams<keyof SearchParams>
): UseQueryResult<ReturnType[]> => {
  return useQuery<ReturnType[]>({
    queryKey: [QUERY_KEYS.SIGNATORIES, searchParams, sortParams],
    queryFn: () => getSignatories(searchParams, sortParams),
  });
};

export default useGetSignatories;
