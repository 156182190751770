import { useTheme } from "@mui/material";
import { useForm, Controller } from "react-hook-form";
import { AddCommentsWrap } from "./add-comment.style";
import { Stack } from "@components/stack";
import { Box, Button, Select, Textarea, Typography } from "@ntpkunity/controls";
import { TCommentsDiaryDetails } from "@_types/forms/diaryComments";
import { useParams } from "react-router-dom";
import { CommentCategory } from "@helpers/enum";
import { getUserSettings } from "@helpers/utils";
import { useState } from "react";

export const AddComments = ({ onAddComment }) => {
  const theme = useTheme();
  const commentCategoryOptions = [
    ...Object.entries(CommentCategory).map(([key, value]) => ({
      text: value,
      value: key,
    })),
  ];
  const categoryValueMap = new Map(
    Object.entries(CommentCategory).map(([key, value]) => [key, value])
  );

  const defaultCategory = commentCategoryOptions[0].value;
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<TCommentsDiaryDetails>({
    defaultValues: {
      comment: "",
      category: defaultCategory,
    },
  });
  const [addCommentInProgress, setAddCommentInProgress] = useState(false);
  const { proposalId } = useParams<{ proposalId?: string }>();
  const onSubmit = async (data: TCommentsDiaryDetails) => {
    setAddCommentInProgress(true);
    const categoryValue = categoryValueMap.get(data.category) || data.category;
    const userName = getUserSettings()?.user_name || "";
    const payload: TCommentsDiaryDetails = {
      ...data,
      created_by: userName,
      order_reference_id: proposalId,
      category: categoryValue,
    };
    try {
      await onAddComment(payload);
      reset();
      setAddCommentInProgress(false);
    } catch (error) {
      setAddCommentInProgress(false);
    }
  };

  return (
    <AddCommentsWrap theme={theme} className="add-comments-wrap">
      <Stack
        paddingMd={3}
        paddingXs={3}
        title="Add Comments"
        actionArea={
          <>
            <Box theme={theme} className="custom-dropdown">
              <Typography
                theme={theme}
                className="text-muted"
                variant="body2"
                component={"span"}
                display={"block"}
              >
                Category:
              </Typography>
              <Controller
                name="category"
                control={control}
                render={({ field }) => (
                  <Select
                    theme={theme}
                    disablePortal={false}
                    items={commentCategoryOptions}
                    value={field.value}
                    onChange={(e) => {
                      field.onChange(e.target.value as string);
                    }}
                    {...field}
                  />
                )}
              />
            </Box>
          </>
        }
      >
        <Box theme={theme} mt={3}>
          <Controller
            name="comment"
            control={control}
            rules={{ required: "Comment is required" }}
            render={({ field }) => (
              <>
                <Textarea
                  theme={theme}
                  placeholder="Type here..."
                  label="Add a Comment"
                  fullWidth
                  type={"text"}
                  id={""}
                  rows={3}
                  {...field}
                  error={errors?.comment?.message}
                />
              </>
            )}
          />

          <Box theme={theme} mt={2}>
            <Button
              theme={theme}
              type="button"
              text="Post Comment"
              primary
              onClick={handleSubmit(onSubmit)}
              disabled={addCommentInProgress}
              className="w-m-100"
            />
          </Box>
        </Box>
      </Stack>
    </AddCommentsWrap>
  );
};
