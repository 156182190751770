import { useMutation } from "react-query";
import { generateDocumentPresignedUrl } from "@services/flex-reporting.service";
import { Events } from "@helpers/enum";
import { DownloadParams } from "@_types/downloadDocuments";
import { downloadDocumentByEvent } from "@services/dms.service";

export const useDownloadDocs = () => {
  return useMutation(downloadDocs);
};

export const usePreviewDocs = () => {
  return useMutation(previewDocs);
};

const downloadDocs = async (params: {
  eventName: Events;
  payload: DownloadParams;
}) => {
  const keys = await downloadDocumentByEvent(params);
  const { template_url } = await generateDocumentPresignedUrl({
    key: keys?.[0],
  });
  const link = document.createElement("a");
  link.href = template_url;
  link.setAttribute("download", `Quotation_${new Date()}.docx`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const previewDocs = async (key: any) => {
  const { template_url } = await generateDocumentPresignedUrl({ key });
  return template_url;
};
