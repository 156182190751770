import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";

export const AuditTrailWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.audit-trail-wrap": {
    height: "100%",
    ".stack-wrap": {
      height: "100%",
      ".stack-header": {
        padding: "8px 24px 24px 24px",
      },
    },
    ".audit-trail-list-wrap": {
      margin: "0 -16px",
      padding: "0 16px",
      overflowX: "hidden",
      overflowY: "auto",
      scrollbarWidth: "thin",
      [theme.breakpoints.up("md")]: {
        maxHeight: "calc(100vh - 146px)",
        "&::-webkit-scrollbar": {
          width: 7,
          height: 7,
        },
        "&::-webkit-scrollbar-track": {
          padding: "0 1px",
          backgroundColor: "#f0f0f0",
        },
        "&::-webkit-scrollbar-thumb": {
          transition: "all 0.3s",
          backgroundColor: "#cdcdcd",
        },
        "&::-webkit-scrollbar-thumb:hover": {
          backgroundColor: "#a6a6a6",
        },
      },
      ".list-item": {
        display: "flex",
        alignItems: "flex-start",
        gap: 16,
        padding: 16,
        borderTop: "1px solid" + theme.palette.divider,
        "&:first-child": {
          paddingTop: 32,
        },
        ".seperator": {
          color: theme.palette.grey[50],
        },
      },
    },
  },
  "&.audit-trail-popup-wrap": {
    ".stack-wrap": {
      padding: 0,
      ".stack-header": {
        display: "none",
      },
      ".audit-trail-list-wrap": {
        ".list-item": {
          "&:first-child": {
            paddingTop: 0,
            borderTop: "none",
          },
        },
      },
    },
  },
}));
