import { useTheme } from "@mui/material";
import { Button } from "@ntpkunity/controls";
import { FinancialConfigWrap } from "./financial-configurations.style";
import { FinancialConfigurationTabs } from "./config-tabs/config-tabs.component";
import { PageHeader, Stack } from "@components";
import { FC, useContext, useEffect, useMemo, useState } from "react";

import {
  FlatRateCalculationBasis,
  useConfigurationContext,
} from "@ntpkunity/controls-common";
import { useSaveFinancialConfigurations } from "@hooks/mutations/useSaveConfigurations";
import { FormProvider, useForm } from "react-hook-form";
import useGetFinanceTypes from "@hooks/queries/useGetFinanceTypes";
import { useSnackbarContext } from "@contexts/snackbar";
import { useQueryClient } from "react-query";
import { QUERY_KEYS } from "@helpers/query";
import useGetPermissions from "@hooks/queries/useGetPermissions";
import { UMSStoreContext } from "@ntpkunity/controls-ums";

export const FinancialConfigurations: FC<{}> = () => {
  const theme = useTheme();
  const {
    states: { productId: productId },
  } = useContext(UMSStoreContext) as any;
  const { data: umsPermissionsData } = useGetPermissions(productId?.productId);
  const { setSnackbar } = useSnackbarContext();
  const queryClient = useQueryClient();
  const { data: configurations } = useConfigurationContext();

  const { mutate: saveFinancialConfigurations } =
    useSaveFinancialConfigurations();
  const { data: financeConfigurations } = useGetFinanceTypes();
  const [financeType, setFinanceType] = useState(
    financeConfigurations[0]?.code
  );
  const defaultValues = useMemo(() => {
    return Object.keys(configurations).reduce((acc, key) => {
      const permissions = umsPermissionsData
        ?.filter((permission) => permission?.name.includes(key?.toLowerCase()))
        .sort((a, b) => a.id - b.id);

      acc[key] = {
        ...configurations[key],
        flatRateCalculationBasis:
          configurations[key].flatRateCalculationBasis ||
          FlatRateCalculationBasis.CALENDAR_DATES,
        paymentsIncludingBalloon:
          configurations[key].paymentsIncludingBalloon || false,
        financeType: key,
        permissions,
      };
      return acc;
    }, {});
  }, [umsPermissionsData]);
  const form = useForm({
    shouldUnregister: false,
    defaultValues: defaultValues,
  });

  useEffect(() => {
    form.reset(defaultValues);
  }, [umsPermissionsData]);

  const onsubmit = (data) => {
    const updatedFees = data[financeType]?.fees?.map((fee) => ({
      PaymentNumber: fee.paymentNumber ?? 0,
      FirstDueDate: new Date().toString(),
      Name: fee?.name,
      Frequency: fee?.type,
      Occurance: fee?.frequency,
      Amount: fee?.amount,
      IsEditable: fee?.isEditable,
      FirstFeePaymentDueAtStartDate: fee?.firstFeePaymentDueAtStartDate,
    }));
    const updatedData = {
      AmortizationMethod: data[financeType]?.amortizationMethod,
      APRCalculationMethod: data[financeType]?.aprCalculationMethod,
      InitialCashOutFlowForFlatRate:
        data[financeType]?.initialCashOutFlowForFlatRate,
      CommissionCalculationMethod:
        data[financeType]?.commissionCalculationMethod,
      IsCommissionPassToCustomer: data[financeType]?.isCommissionPassToCustomer,
      IsVATApplicable: data[financeType]?.isVATApplicable,
      Fees: updatedFees,
      Tax: 0,
      TaxType: data[financeType]?.taxType,
      GrossProfit: data[financeType]?.grossProfit,
      FlatRateCalculationBasis: data[financeType]?.flatRateCalculationBasis,
      PaymentsIncludingBalloon: data[financeType]?.paymentsIncludingBalloon,
    };
    saveFinancialConfigurations(
      { ...updatedData, financeType },
      {
        onSuccess() {
          setSnackbar(
            {
              message: "Configurations successfully saved",
              open: true,
              variant: null,
            },
            10000
          );
          queryClient.invalidateQueries([QUERY_KEYS.QUOTATION_CONFIGURATIONS]);
        },
        onError() {
          setSnackbar(
            {
              message: "Error occurred while saving data",
              open: true,
              variant: "error",
            },
            10000
          );
        },
      }
    );
  };
  return (
    <>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onsubmit)}>
          <FinancialConfigWrap theme={theme} className="financial-config-wrap">
            <PageHeader
              title={"Financial Configurations"}
              actionArea={
                <>
                  <Button
                    theme={theme}
                    primary
                    type="submit"
                    text={"Save Changes"}
                    onClick={() => {}}
                  />
                </>
              }
            />
            <Stack paddingMd={3} paddingXs={3}>
              <FinancialConfigurationTabs
                financeType={financeType}
                setFinanceType={setFinanceType}
                financeConfigurations={financeConfigurations}
              />
            </Stack>
          </FinancialConfigWrap>
        </form>
      </FormProvider>
    </>
  );
};
