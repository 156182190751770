import { TableCell, TableRow, useTheme } from "@mui/material";
import {
  Box,
  Button,
  DataTable,
  Icon,
  Input,
  Pagination,
} from "@ntpkunity/controls";
import { DataTableWrap } from "@styles/data-table-wrap";
import AssetTableRow from "./asset-details-row/asset-details-row.component";

const AssetDetailsTable = () => {
  const theme = useTheme();
  return (
    <DataTableWrap theme={theme} className="table-pagination-button">
      <Box theme={theme} className="scroll">
        <Box className="scroll-hide spr-border" theme={theme} />
      </Box>
      <DataTable
        theme={theme}
        theadChildren={
          <>
            <TableRow>
              <TableCell>Quantity</TableCell>
              <TableCell>Supplier Name</TableCell>
              <TableCell>Asset Type</TableCell>
              <TableCell>Asset Cost</TableCell>
              <TableCell className="action-cell fixed-cell" />
            </TableRow>
            <TableRow className="filters-row">
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    type={""}
                    startAdornment={""}
                    endAdornment={""}
                    id={""}
                    placeholder="Search"
                  ></Input>
                  <Button
                    defaultBtn
                    theme={theme}
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    type={""}
                    startAdornment={""}
                    endAdornment={""}
                    id={""}
                    placeholder="Search"
                  ></Input>
                  <Button
                    defaultBtn
                    theme={theme}
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    type={""}
                    startAdornment={""}
                    endAdornment={""}
                    id={""}
                    placeholder="Search"
                  ></Input>
                  <Button
                    defaultBtn
                    theme={theme}
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    type={""}
                    startAdornment={""}
                    endAdornment={""}
                    id={""}
                    placeholder="Search"
                  ></Input>
                  <Button
                    defaultBtn
                    theme={theme}
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell className="action-cell fixed-cell" />
            </TableRow>
          </>
        }
        tbodyChildren={
          <>
            <AssetTableRow />
          </>
        }
      />
      <Pagination theme={theme} />
    </DataTableWrap>
  );
};

export default AssetDetailsTable;
