import { useGetFinanceTypes } from "@hooks/queries";
import { useTheme } from "@mui/material";
import { Skeleton } from "@ntpkunity/controls";

export const FinanceTypeCell = ({
  financeTypeCode,
}: {
  financeTypeCode: string;
}) => {
  const theme = useTheme();
  const { isLoading: financeTypesLoading, data: financeTypes } =
    useGetFinanceTypes();

  if (financeTypesLoading) {
    return <Skeleton theme={theme} height={14} />;
  }

  const financeTypeName =
    financeTypes?.find((ft) => ft.code === financeTypeCode)?.name || "";

  return <>{financeTypeName}</>;
};
