import { AddDocumentDialogProps } from "./add-document-dialog.props";
import { useForm, FormProvider, useFormContext } from "react-hook-form";
import { useProposalFieldArrays } from "@contexts/proposal-form";
import { TDocumentDetails, DocumentType, TProposalForm } from "@_types";
import DocumentFormDialog from "../document-form-dialog/document-form-dialog.component";
import { useRef } from "react";

const AddDocumentDialog = ({
  openDialog,
  setOpenDialog,
  setupData,
  editDocumentId,
}: AddDocumentDialogProps) => {
  const formMethods = useForm<TDocumentDetails>();
  const {
    documentMethods: { append: appendDocument, fields: documents },
  } = useProposalFieldArrays();

  const documentToEdit = documents?.find((doc) => doc?.id === editDocumentId);
  const fileDetailsRef = useRef<DocumentType[]>();

  const handleSaveDocument = () => {
    appendDocument({
      ...formMethods.getValues(),
      documents: fileDetailsRef.current,
    });
    formMethods.reset();
    setOpenDialog(false);
  };

  const closeDialog = () => {
    formMethods.reset();
    setOpenDialog(false);
  };

  return (
    <FormProvider {...formMethods}>
      <DocumentFormDialog
        setupData={setupData}
        openDialog={openDialog}
        documentType={documentToEdit?.type}
        closeDialog={closeDialog}
        handleSaveDocument={handleSaveDocument}
        isEditMode={false}
        ref={fileDetailsRef}
      />
    </FormProvider>
  );
};

export default AddDocumentDialog;
