import Entry from "../entry/entry.component";
import { useCalculationsContext } from "@ntpkunity/controls-common";

const Rates = () => {
  const {
    data: { rates },
  } = useCalculationsContext();

  return (
    <>
      <Entry title="Gross Yield" value={`${rates.grossYield.toFixed(2)}%`} />
      <Entry title="Net Yield" value={`${rates.netYield.toFixed(2)}%`} />
      <Entry title="APR" value={`${rates.apr.toFixed(2)}%`} />
      <Entry
        title="Flat Rate Excl. Commission"
        value={`${rates.flatRateExclCommission.toFixed(2)}%`}
      />
      <Entry
        title="Flat Rate Incl. Commission"
        value={`${rates.flatRateInclCommission.toFixed(2)}%`}
      />
    </>
  );
};

export default Rates;
