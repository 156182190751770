import { useTheme } from "@mui/material";
import { Box, Button, Grid, Input, Typography } from "@ntpkunity/controls";
import { RegistrationNumberWrap } from "./companies-by-reg-num.style";
import {
  CompaniesByRegNumResponse,
  CompanyRegNumForm,
  CompanySearchReqParams,
} from "@_types";
import { Controller, useForm } from "react-hook-form";
import { CompanyInfo } from "../companies-by-name/company-info/company-info.component";

type CompaniesByRegNumProps = {
  companiesLoading: boolean;
  errorInCompanies: boolean;
  searchParams: CompanySearchReqParams;
  setSearchParams: React.Dispatch<React.SetStateAction<CompanySearchReqParams>>;
  companies: CompaniesByRegNumResponse[];
  errorMessage: string | undefined;
  selectedCompany: CompaniesByRegNumResponse;
  setSelectedCompany: React.Dispatch<
    React.SetStateAction<CompaniesByRegNumResponse>
  >;
};

export const CompaniesByRegNum = ({
  companiesLoading,
  errorInCompanies,
  companies,
  errorMessage,
  searchParams,
  setSearchParams,
  selectedCompany,
  setSelectedCompany,
}: CompaniesByRegNumProps) => {
  const theme = useTheme();
  const { control, getValues } = useForm<CompanyRegNumForm>();

  const handleFetchCompanies = () => {
    setSearchParams({
      ...searchParams,
      registration_number: getValues().registrationNumber,
    });
  };

  return (
    <RegistrationNumberWrap theme={theme} className="registration-number-wrap">
      <Grid theme={theme} container item spacing={2}>
        <Grid theme={theme} item xs={9}>
          <Controller
            name="registrationNumber"
            control={control}
            render={({ field }) => {
              return (
                <Input
                  theme={theme}
                  type="text"
                  placeholder="Type Here..."
                  label="Company Registration Number"
                  fullWidth
                  {...field}
                />
              );
            }}
          />
        </Grid>
        <Grid theme={theme} item xs={3}>
          <Button
            theme={theme}
            text="Lookup"
            type="button"
            fullWidth
            primary
            onClick={handleFetchCompanies}
          />
        </Grid>
      </Grid>
      {!!searchParams.registration_number && (
        <Box theme={theme} className="list-wrap">
          {errorInCompanies || companiesLoading || !companies.length ? (
            <Typography
              theme={theme}
              variant="body2"
              component="span"
              display="block"
              textAlign="center"
            >
              {errorInCompanies
                ? errorMessage ||
                  "Could not find any company with the given registration number"
                : companiesLoading
                ? "Searching Company..."
                : "No Company found."}
            </Typography>
          ) : (
            companies.map((company) => (
              <CompanyInfo
                key={company.company_number}
                company={company}
                selectedCompany={selectedCompany}
                setSelectedCompany={setSelectedCompany}
              />
            ))
          )}
        </Box>
      )}
    </RegistrationNumberWrap>
  );
};
