import { Stack } from "@components";
import { Box, Icon } from "@ntpkunity/controls";
import { useTheme } from "@mui/material";

type ShowInputFieldsButtonProps = {
  onClick: () => void;
};

export const ShowInputFieldsButton = ({
  onClick,
}: ShowInputFieldsButtonProps) => {
  const theme = useTheme();
  return (
    <Box
      theme={theme}
      margin={{ xs: "24px 0 0 0", md: "16px -24px 0px -24px" }}
      className="comparison-btn"
    >
      <Stack
        className="cp"
        paddingMd={3}
        paddingXs={3}
        title="Quotation Comparison"
        onClick={onClick}
        actionArea={<Icon name="ChevronDown" />}
      />
    </Box>
  );
};
