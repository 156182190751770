import { TableCell, TableRow, useTheme } from "@mui/material";
import { DataTable } from "@ntpkunity/controls";
import { DataTableWrap } from "@styles/data-table-wrap";
import { pounds } from "@helpers/utils";
import { useCalculationsContext } from "@ntpkunity/controls-common";

export const Rentals = () => {
  const {
    data: { rentalSummary },
  } = useCalculationsContext();

  const theme = useTheme();
  return (
    <DataTableWrap theme={theme} className="no-min-width">
      <DataTable
        theme={theme}
        theadChildren={
          <>
            <TableRow>
              <TableCell>From - To</TableCell>
              <TableCell sx={{ textAlign: "right" }}>
                Amount (W/O VAT)
              </TableCell>
            </TableRow>
          </>
        }
        tbodyChildren={
          rentalSummary &&
          rentalSummary.length > 0 &&
          rentalSummary.map((rental) => (
            <TableRow className="child-tr" key={`rental-${rental.startTerm}`}>
              <TableCell>
                {rental.startTerm} - {rental.endTerm}
              </TableCell>
              <TableCell sx={{ textAlign: "right" }}>
                {pounds.format(rental.rentalAmount)}
              </TableCell>
            </TableRow>
          ))
        }
      />
    </DataTableWrap>
  );
};
