import { useState } from "react";
import { useTheme } from "@mui/material";
import { Accordion, Box, Button, Grid } from "@ntpkunity/controls";
import { CustomerDetails } from "./customer-details/customer-details.component";
import { ProposalSummaryWrap } from "./proposal-summary.style";
import { PaymentDetails } from "./payment-details/payment-details.component";
import { DirectorsDetails } from "../proposal-details/customer-details/director-details/director-details.component";
import { AssetDetailsTable } from "../asset-details";
import { Stack } from "@components/stack";
import { Banner } from "../banner/banner.component";
import { useFieldArray, useFormContext } from "react-hook-form";
import { TCommentsDetails, TProposalForm } from "@_types";
import { ClientTypes, CommentCategory, FinanceType } from "@helpers/enum";
import { TotalAssetCost } from "./total-asset-cost.component";
import { CreditDocuments } from "../credit-documents";
import { ProposalSummaryProps } from "./proposal-summary.props";
import { CommentList } from "@components/customer-proposal";
import AddCommentDialog from "@components/customer-proposal/add-comment-dialog/add-comment-dialog.component";
import { useGetFinanceTypeName, useGetClientTypeName } from "@hooks";

export const ProposalSummary = ({
  proposalSubmitted,
}: ProposalSummaryProps) => {
  const theme = useTheme();
  const { getValues, control, watch } = useFormContext<TProposalForm>();
  const financeCode: string = watch("proposalDetails.financeType");
  const clientType = getValues("customerDetails.clientType");
  const clientTypeName = useGetClientTypeName(clientType);
  const shouldShowDirectors = clientTypeName !== ClientTypes.INDIVIDUAL;
  const [dialogOpen, setDialogOpen] = useState(false);
  const financeType: string = useGetFinanceTypeName(financeCode);
  const isAssetsTabDisabled: boolean = financeType === FinanceType.LOAN;
  const {
    fields: comments,
    prepend,
    remove,
    update,
  } = useFieldArray({
    control,
    name: "orderComments",
  });
  const filteredComments = comments?.filter(
    (comment) => comment.category === CommentCategory.UNDERWRITING
  );
  const [editIndex, setEditIndex] = useState<number | null>(null);
  const handleCommentAdd = (newComment: TCommentsDetails) => {
    if (editIndex !== null) {
      update(editIndex, newComment);
      setEditIndex(null);
    } else {
      prepend(newComment);
    }
    setDialogOpen(false);
  };

  const handleCommentEdit = (index: number) => {
    setEditIndex(index);
    setDialogOpen(true);
  };

  const handleCommentDelete = (index: number) => {
    remove(index);
  };

  return (
    <>
      <ProposalSummaryWrap theme={theme} className="proposal-summary-wrap">
        {proposalSubmitted && (
          <Box theme={theme} mb={2} display={{ xs: "none", md: "block" }}>
            <Banner />
          </Box>
        )}

        <Grid theme={theme} container spacing={2}>
          <Grid theme={theme} item xl={9} lg={8} md={7} sm={12} xs={12}>
            <Box theme={theme} display={{ xs: "block", md: "none" }}>
              <TotalAssetCost />
              {proposalSubmitted && (
                <Box theme={theme} mb={2}>
                  <Banner />
                </Box>
              )}
            </Box>
            <Box theme={theme}>
              <CustomerDetails />
            </Box>
            {shouldShowDirectors && (
              <Box theme={theme} className="directors-details" mt={2}>
                <DirectorsDetails showAddBtn={false} />
              </Box>
            )}
            <Box theme={theme} mt={2}>
              <Stack
                marginTopMd={2}
                marginTopXs={2}
                paddingXs={3}
                paddingMd={3}
                title="Comments for Underwriter"
                actionArea={
                  <>
                    <Button
                      theme={theme}
                      secondary
                      text={"Add Comment"}
                      onClick={() => {
                        setEditIndex(null);
                        setDialogOpen(true);
                      }}
                    />
                  </>
                }
              >
                <CommentList
                  comments={comments}
                  onEdit={handleCommentEdit}
                  onDelete={handleCommentDelete}
                />
              </Stack>
            </Box>
            <Box theme={theme} className="directors-details" mt={2}>
              <CreditDocuments showAddBtn={false} />
            </Box>

            {!isAssetsTabDisabled && (
              <Stack
                className="collapse-wrap desktop-disable"
                paddingXs={3}
                paddingMd={3}
                marginTopMd={2}
                marginTopXs={2}
              >
                <Accordion
                  theme={theme}
                  items={[
                    {
                      content: <AssetDetailsTable loadedFromSummary />,
                      title: "Asset Details",
                      key: "string",
                      isExpanded: true,
                      id: "string",
                      ariaControls: "string",
                    },
                  ]}
                />
              </Stack>
            )}
          </Grid>
          <Grid
            theme={theme}
            item
            xl={3}
            lg={4}
            md={5}
            sm={12}
            xs={12}
            display={{ xs: "none", md: "block" }}
          >
            <PaymentDetails />
          </Grid>
        </Grid>
      </ProposalSummaryWrap>
      <AddCommentDialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        onCommentAdd={handleCommentAdd}
        commentToEdit={editIndex !== null ? filteredComments[editIndex] : null}
      />
    </>
  );
};
