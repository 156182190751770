import { useGetClientTypes } from "@hooks/queries";
import { useTheme } from "@mui/material";
import { Skeleton } from "@ntpkunity/controls";

export const CustomerTypeCell = ({
  customerTypeCode,
}: {
  customerTypeCode: string;
}) => {
  const theme = useTheme();
  const { isLoading: customerTypesLoading, data: customerTypes } =
    useGetClientTypes();

  if (customerTypesLoading) {
    return <Skeleton theme={theme} height={14} />;
  }

  const customerTypeName =
    customerTypes.find((ft) => ft.code === customerTypeCode)?.description || "";

  return <>{customerTypeName}</>;
};
