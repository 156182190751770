import { TAssetDetails } from "@_types";
import { useFormContext } from "react-hook-form";
import { TInputDef } from "@components/proposal/proposal-details/types";
import {
  AmountType,
  AssetCategories,
  HardAssetConditions,
} from "@helpers/enum";
import { useAssetSetups } from ".";
import { removeDeletedEntities } from "@helpers/utils";

const useAssetInputDefs = () => {
  const { watch, getValues } = useFormContext<TAssetDetails>();

  const { assetCategories, assetTypes, assetSubTypes, assetConditions } =
    useAssetSetups();

  const assetType = watch("type");
  const assetCategory = watch("category");
  const condition = watch("condition");

  const assetCategoryName = !!assetCategory
    ? assetCategories.find((category) => category.code === assetCategory)
        ?.description
    : "";

  const conditionName = !!condition
    ? assetConditions.find((c) => c.code === condition)?.description
    : "";

  const getInputDefs = (): TInputDef[] => {
    return [
      {
        name: "category",
        formElement: "Select",
        label: "Asset Category",
        items:
          removeDeletedEntities(getValues().category, assetCategories)?.map(
            (category) => {
              return {
                text: category.description,
                value: category.code,
              };
            }
          ) || [],
      },
      {
        name: "type",
        formElement: "Select",
        label: "Asset Type",
        items:
          removeDeletedEntities(
            getValues().type,
            assetTypes[assetCategory]
          )?.map((type) => ({
            text: type.name,
            value: type.code,
          })) || [],
      },
      {
        name: "subType",
        formElement: "Select",
        label: "Asset Sub-Type",
        items: !!assetType
          ? removeDeletedEntities(
              getValues().subType,
              assetSubTypes[assetType]
            )?.map((subType) => ({
              text: subType.name,
              value: subType.code,
            })) || []
          : [],
        disabled: assetCategory === AssetCategories.SOFT_ASSET,
      },
      {
        name: "condition",
        formElement: "Select",
        label: "Asset Condition",
        items:
          removeDeletedEntities(getValues().condition, assetConditions)?.map(
            (condition) => ({
              text: condition.description,
              value: condition.code,
            })
          ) || [],
        disabled: assetCategoryName === AssetCategories.SOFT_ASSET,
      },
      {
        name: "age",
        formElement: "Input",
        placeHolder: "0",
        label: "Asset Age",
        type: "number",
        disabled: conditionName !== HardAssetConditions.USED,
      },
      {
        name: "cost",
        formElement: "Input",
        placeHolder: "0.00",
        label: "Asset Cost",
        type: "text",
        maskDecimalScale: 2,
        masking: true,
        maskNumeric: true,
        startAdornment: AmountType.POUND,
      },
      {
        name: "rvBalloonAmount",
        formElement: "Input",
        placeHolder: "0.00",
        label: "RV/Balloon Amount",
        type: "text",
        maskDecimalScale: 2,
        masking: true,
        maskNumeric: true,
        startAdornment: AmountType.POUND,
      },
      {
        name: "description",
        formElement: "Input",
        placeHolder: "Type Here...",
        label: "Asset Description",
        type: "text",
      },
      {
        name: "supplierName",
        formElement: "Input",
        placeHolder: "Type Here...",
        label: "Supplier Name",
        type: "text",
      },
      {
        name: "quantity",
        formElement: "Input",
        placeHolder: "0",
        label: "Quantity",
        type: "text",
        maskDecimalScale: 0,
        masking: true,
        maskNumeric: true,
      },
      {
        name: "totalCost",
        formElement: "Input",
        placeHolder: "Total Asset Cost",
        label: "Total Cost",
        type: "text",
        masking: true,
        maskNumeric: true,
        maskDecimalScale: 2,
        startAdornment: AmountType.POUND,
        disabled: true,
      },
      {
        name: "totalRvBalloonAmount",
        formElement: "Input",
        placeHolder: "Total RV/Balloon Amount",
        label: "Total RV/Balloon Amount",
        type: "text",
        masking: true,
        maskNumeric: true,
        maskDecimalScale: 2,
        startAdornment: AmountType.POUND,
        disabled: true,
      },
    ];
  };

  return getInputDefs();
};

export default useAssetInputDefs;
