import { useTheme } from "@mui/material";
import { useFormContext, Controller } from "react-hook-form";
import { Autocomplete, Input, Select } from "@ntpkunity/controls";
import { TInputDef } from "@components/proposal/proposal-details/types";

const ControlledFormElement = (props: TInputDef) => {
  const theme = useTheme();
  const { control } = useFormContext();
  if (props.formElement === "Input") {
    return (
      <Controller
        name={props.name}
        control={control}
        render={({ field }) => {
          return (
            <Input
              theme={theme}
              fullWidth
              placeholder={props.placeHolder}
              label={props.label}
              type={props.type}
              masking={props.masking}
              maskAlphaNumeric={props.maskAlphaNumeric}
              inputMask={props.inputMask}
              maskNumeric={props.maskNumeric}
              startAdornment={props.startAdornment}
              endAdornment={props.endAdornment}
              maskDecimalScale={props.maskDecimalScale}
              value={field.value}
              readOnly={props.disabled}
              {...field}
              onChange={(value) => {
                // input[type=number] doesn't work as expected for mozilla firefox
                // See bug https://bugzilla.mozilla.org/show_bug.cgi?id=1398528
                const isNumberField = props.type === "number";
                if (isNumberField) {
                  const valueWithoutChar = (value as number)
                    .toString()
                    .replace(/[^0-9.,]+/, "");

                  if (valueWithoutChar === "") value = undefined;
                  else value = Number(valueWithoutChar);
                }
                const sanitizedValue = !!props.sanitizeChange
                  ? props.sanitizeChange(value)
                  : value;
                field.onChange(sanitizedValue);
              }}
            />
          );
        }}
      />
    );
  }

  if (props.formElement === "Select") {
    return (
      <Controller
        name={props.name}
        control={control}
        disabled={!!props.disabled}
        render={({ field }) => {
          return (
            <Select
              theme={theme}
              label={props.label}
              value={props.items[0]}
              items={props.items}
              readOnly={!props.items?.length}
              {...field}
            />
          );
        }}
      />
    );
  }

  if (props.formElement === "AutoComplete") {
    const filterOptions = (options, { inputValue }) => {
      const filtered = options.filter((option) =>
        option.label.toLowerCase().includes(inputValue.toLowerCase())
      );
      if (filtered.length === 0) {
        return [...filtered, { label: "No option", isNoOption: true }];
      }
      return filtered;
    };

    return (
      <Controller
        control={control}
        name={props.name}
        render={({ field: { value, ...field } }) => {
          return (
            <Autocomplete
              disablePortal
              freeSolo={false}
              theme={theme}
              items={props.items as any[]}
              options={props.items as any[]}
              getOptionLabel={props.getOptionLabel}
              label={props.label}
              renderOption={props.renderOption}
              value={(props.items as any[])?.find(
                (item) => item.label === value
              )}
              {...field}
              onChange={props.onChange}
              filterOptions={filterOptions}
              autoComplete
              getOptionDisabled={(option) => option.isNoOption}
              readOnly={props.disabled}
            />
          );
        }}
      />
    );
  }

  return <></>;
};

export default ControlledFormElement;
