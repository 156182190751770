import { styled } from "@mui/material/styles";
import { Box, IBoxProps } from "@ntpkunity/controls";

export const AuthLayoutWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  "&.auth-layout-wrap": {
    minHeight: "100vh",
    backgroundColor: theme.palette.common.white,
    display: "flex",
    alignItems: "center",
    padding: 24,
    ".auth-left-img": {
      position: "fixed",
      top: 24,
      left: 24,
      width: 348,
      flex: "0 0 auto",
      height: "calc(100% - 48px)",
      borderRadius: 16,
      overflow: "hidden",
      img: {
        width: "100%",
        height: "100%",
        objectFit: "cover",
        objectposition: "center center",
      },
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },
    ".auth-right-panel": {
      flexGrow: 1,
      padding: "0px 0 0 24px",
      marginLeft: 348,
      ".right-content": {
        maxWidth: 520,
        margin: "auto",
        minHeight: "calc(100vh - 48px)",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        [theme.breakpoints.down("md")]: {
          minHeight: "auto",
        },
        ".logo-area": {
          maxHeight: 50,
          img: {
            maxHeight: 50,
          },
        },
      },
      [theme.breakpoints.down("md")]: {
        padding: 0,
        marginLeft: 0,
      },
    },
  },
}));
