import { Box, Checkbox, Grid, Typography } from "@ntpkunity/controls";
import { useTheme } from "@mui/material";
import { CompaniesByNameResponse, CompaniesByRegNumResponse } from "@_types";

type CompanyInfoProps = {
  company: CompaniesByNameResponse | CompaniesByRegNumResponse;
  selectedCompany: CompaniesByNameResponse | CompaniesByRegNumResponse;
  setSelectedCompany: React.Dispatch<
    React.SetStateAction<CompaniesByNameResponse | CompaniesByRegNumResponse>
  >;
};

export const CompanyInfo = ({
  company,
  selectedCompany,
  setSelectedCompany,
}: CompanyInfoProps) => {
  const theme = useTheme();
  const checked = selectedCompany?.company_number === company.company_number;
  const isRegNumRes = Array.isArray(company.sic_code);
  return (
    <Box theme={theme} className="list" mt={3}>
      <Checkbox
        theme={theme}
        label={company.company_name || ""}
        checkBoxChecked={checked}
        onChange={(_, checked) => {
          if (checked) {
            setSelectedCompany(company);
          } else setSelectedCompany(undefined);
        }}
      />
      <Box theme={theme} className="list-item">
        <Grid theme={theme} container item rowSpacing={1} columnSpacing={3}>
          <Grid theme={theme} item xs={6} md={4.7}>
            <Typography
              theme={theme}
              component="span"
              variant="body2"
              className="text-muted"
              flexShrink={0}
            >
              Trading As
            </Typography>
          </Grid>
          <Grid theme={theme} item xs={6} md={7.3}>
            <Typography
              theme={theme}
              component="span"
              variant="body2"
              flexGrow={1}
            >
              -
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box theme={theme} className="list-item">
        <Grid theme={theme} container item spacing={3}>
          <Grid theme={theme} item xs={6} md={4.7}>
            <Typography
              theme={theme}
              component="span"
              variant="body2"
              className="text-muted"
              flexShrink={0}
            >
              Registration Number
            </Typography>
          </Grid>
          <Grid theme={theme} item xs={6} md={7.3}>
            <Typography
              theme={theme}
              component="span"
              variant="body2"
              flexGrow={1}
            >
              {company.company_number}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box theme={theme} className="list-item">
        <Grid theme={theme} container item spacing={3}>
          <Grid theme={theme} item xs={6} md={4.7}>
            <Typography
              theme={theme}
              component="span"
              variant="body2"
              className="text-muted"
              flexShrink={0}
            >
              Address
            </Typography>
          </Grid>
          <Grid theme={theme} item xs={6} md={7.3}>
            <Typography
              theme={theme}
              component="span"
              variant="body2"
              flexGrow={1}
            >
              {company.address}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box theme={theme} className="list-item">
        <Grid theme={theme} container item spacing={3}>
          <Grid theme={theme} item xs={6} md={4.7}>
            <Typography
              theme={theme}
              component="span"
              variant="body2"
              className="text-muted"
              flexShrink={0}
            >
              SIC Code
            </Typography>
          </Grid>
          <Grid theme={theme} item xs={6} md={7.3}>
            <Typography
              theme={theme}
              component="span"
              variant="body2"
              flexGrow={1}
            >
              {(isRegNumRes ? company.sic_code[0] : company.sic_code) || "-"}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
