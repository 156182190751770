import React from "react";
import { useTheme } from "@mui/material";
import { AuditTrailWrap } from "./audit-trail.style";
import { Stack } from "@components/stack";
import { Box } from "@ntpkunity/controls";
import { AuditTrailContent } from "./audit-trail-content.component";

export const AuditTrail = ({ proposalId }: { proposalId?: string }) => {
  const theme = useTheme();
  return (
    <AuditTrailWrap theme={theme} className="audit-trail-wrap" mb={{ md: -2 }}>
      <Stack title="Status Tracking" paddingMd={2} paddingXs={2}>
        <Box theme={theme}>
          <AuditTrailContent proposalId={proposalId} />
        </Box>
      </Stack>
    </AuditTrailWrap>
  );
};
