import { useGetClientTypes } from "@hooks/queries";

const useClientTypesSetup = () => {
  const clientTypesQuery = useGetClientTypes();

  const clientTypes =
    clientTypesQuery.isSuccess && clientTypesQuery.data?.length
      ? clientTypesQuery.data
      : [];

  return clientTypes;
};

export default useClientTypesSetup;
