import "./root-style.css";
import { createTheme } from "@mui/material";
import { unityTheme } from "@ntpkunity/controls";
import "./theme-builder.css";

export const CustomColors = {
  greyIcon: "#999999",
  textDark: "#080808",
  LloydsPrimary: "#006A4D",
  HsbcPrimary: "#DB0011",
  AppPrimary: "#1A6AB2",
  UbtPrimary: "#EF202F",
};

export const BROKER_APP_THEME = createTheme({
  ...unityTheme,
  palette: {
    ...unityTheme.palette,
    primary: { main: CustomColors.AppPrimary },
    divider: "#E5E5E5",
  },
  typography: {
    ...unityTheme.typography,
    h2: {
      fontSize: 28,
      lineHeight: "40px",
      fontWeight: unityTheme.typography.h2.fontWeight,
    },
    button: {
      fontSize: 14,
      lineHeight: "16px",
      fontWeight: unityTheme.typography.button.fontWeight,
      textTransform: unityTheme.typography.button.textTransform,
      outline: unityTheme.typography.button.outline,
      borderRadius: unityTheme.typography.button.borderRadius,
      border: unityTheme.typography.button.border,
    },
  },
});

export { BROKER_APP_THEME_WRAP } from "./global.styles";
