export const QUERY_KEYS = {
  QUOTATION_BY_IDENTIFIER: "quotation-by-identifier",
  ORDER_WORKQUEUE: "order-workqueue",
  GET_SINGLE_PROPOSAL: "get-single-proposal",
  QUOTATION_CONFIGURATIONS: "quotation-configurations",
  DASHBOARD_PROPOSALS: "dashboard-proposals",
  GET_ORDER_STATUS_HISTORY: "get-order-status-history",
  GET_CONFIGURATIONS: "get-configurations",
  GET_DIARY_COMMENTS: "get-diary-comments",
  GET_ALL_COUNTRIES: "get-all-countries",
  SEARCH_COMPANIES: "search-companies",
  SEARCH_ADDRESS: "search-address",
  GET_THEME_BUILDER: "get-theme-builder",
  GET_COMPANY_PROFILE: "get-company-profile",
  GET_ASSET_CATEGORIES: "get-asset-categories",
  GET_ASSET_TYPES: "get-asset-types",
  GET_ASSET_SUB_TYPES: "get-asset-sub-types",
  GET_ASSET_CONDITIONS: "get-asset-conditions",
  GET_PRODUCT_TYPES: "get-product-types",
  GET_FINANCE_TYPES: "get-finance-types",
  GET_CLIENT_TYPES: "get-client-types",
  GET_DMS_DEALER: "get-dms-dealer",
  GET_WORKFLOW_TASKS: "get-workflow-tasks",
  GET_WORKFLOW_TASK_BY_RUNNING_ID: "get-workflow-task-by-running-id",
  GET_PRODUCT_ID: "get-product-id",
  GET_COMPLIANCE_DOCUMENTS: "get-compliance-documents",
  GENERATE_DOCUMENTS: "generate-documents",
  SIGNATORIES: "signatories",
  GET_DOCUMENT_TYPES: "get-document-types",
  GET_GENERATED_DOCUMENTS: "get-generated-documents",
  GET_ALL_DEALERS: "get-all-dealers",
  GET_ALL_LENDERS: "get-all-lenders",
  GET_DEALER_PROFILE: "get-dealer-profile",
};
