import { TableCell, TableRow, useTheme } from "@mui/material";
import { Button, DataTable, Icon } from "@ntpkunity/controls";
import { DataTableWrap } from "@styles/data-table-wrap";

export const Rentals = () => {
  const theme = useTheme();
  return (
    <DataTableWrap theme={theme} className="no-min-width">
      <DataTable
        theme={theme}
        theadChildren={
          <>
            <TableRow className="has-sorting">
              <TableCell>
                From - To
                <span className="sort-action">
                  <Button
                    theme={theme}
                    onClick={() => {}}
                    iconText={<Icon name="AscDescIcon" />}
                  />
                </span>
              </TableCell>
              <TableCell className="text-right">
                Amount (W/O VAT)
                <span className="sort-action">
                  <Button
                    theme={theme}
                    onClick={() => {}}
                    iconText={<Icon name="AscDescIcon" />}
                  />
                </span>
              </TableCell>
            </TableRow>
          </>
        }
        tbodyChildren={
          <>
            <TableRow className="child-tr">
              <TableCell>1 - 2</TableCell>
              <TableCell className="text-right">£ 5,033.89</TableCell>
            </TableRow>
            <TableRow className="child-tr">
              <TableCell>3 - 3</TableCell>
              <TableCell className="text-right">£ 7,433.89</TableCell>
            </TableRow>
            <TableRow className="child-tr">
              <TableCell>4 - 25</TableCell>
              <TableCell className="text-right">£ 5,033.89</TableCell>
            </TableRow>
            <TableRow className="child-tr">
              <TableCell>26 - 26</TableCell>
              <TableCell className="text-right">£ 10,000.00</TableCell>
            </TableRow>
          </>
        }
      />
    </DataTableWrap>
  );
};
