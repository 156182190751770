import "react";
import { useTheme } from "@mui/material";
import { Stack } from "@components/stack";
import { AddSignatoriesWrap } from "../add-signatories/add-signatories.style";
import SignatoriesList from "../add-signatories/signatories-list.component";
import { Box, Button } from "@ntpkunity/controls";
import {
  useChangeProposalStatus,
  usePreviewDocs,
  useSendEmailNotification,
} from "@hooks/mutations";
import {
  ClientTypes,
  DownloadDocument,
  Events,
  OrderStatus,
} from "@helpers/enum";
import { getDownloadDocumentPayload, getUserSettings } from "@helpers/utils";
import {
  GetProposalData,
  useGetAllLenders,
  useGetAssetCategories,
  useGetAssetConditions,
  useGetAssetSubTypes,
  useGetAssetTypes,
  useGetClientTypes,
  useGetCompanyByTenantId,
  useGetFinanceTypes,
  useGetProductTypes,
} from "@hooks/queries";
import { useGetClientTypeName } from "@hooks/useSetupNames";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useQueryClient } from "react-query";
import { QUERY_KEYS } from "@helpers/query";
import { EmailNotificationPayload } from "@_types/emailNotifications";
import { useSnackbarContext } from "@contexts/snackbar";
import { SNACKBAR_MESSAGES } from "@helpers/const";

export const ComplianceDocumentGenerated = ({
  generatedDocuments,
  complianceDocuments,
  signatories,
  commonFilterParams,
  generatedDocumentSuccess,
  documentsGenerated,
  proposal,
}: {
  generatedDocuments: any;
  complianceDocuments: any;
  signatories: any;
  commonFilterParams: any;
  generatedDocumentSuccess: boolean;
  documentsGenerated: boolean;
  proposal: GetProposalData;
}) => {
  const isGeneratedDocument =
    generatedDocuments.length > 0 ||
    generatedDocumentSuccess ||
    proposal.proposal.status === OrderStatus.AWAITING_COMPLIANCE;
  const isButtonEnabled = generatedDocuments.length > 0;
  const theme = useTheme();
  const params = useParams<{ proposalId?: string }>();
  const { setSnackbar } = useSnackbarContext();
  const queryClient = useQueryClient();
  const { mutateAsync: sendEmailNotification } = useSendEmailNotification();
  const { mutateAsync: changeProposalStatus } = useChangeProposalStatus();
  const { data: companyInfo } = useGetCompanyByTenantId();
  const clientType = useGetClientTypeName(
    proposal?.proposal?.customerDetails?.clientType
  );
  const { data: clientTypes } = useGetClientTypes();
  const { data: financeTypes } = useGetFinanceTypes();
  const { data: productTypes } = useGetProductTypes();
  const { data: assetSubTypes } = useGetAssetSubTypes();
  const { data: assetCategories } = useGetAssetCategories();
  const { data: assetConditions } = useGetAssetConditions();
  const { data: assetTypes } = useGetAssetTypes();
  const { data: lenders } = useGetAllLenders();

  const { mutateAsync: previewDocs } = usePreviewDocs();

  useEffect(() => {
    if (isGeneratedDocument) {
      changeProposalStatus({
        identifier: params?.proposalId,
        current_status: OrderStatus.ACCEPTED,
        next_status: OrderStatus.AWAITING_COMPLIANCE,
        created_by: getUserSettings()?.user_name || null,
      });
      queryClient.invalidateQueries([
        QUERY_KEYS.GET_SINGLE_PROPOSAL,
        params?.proposalId,
      ]);
    }
  }, [isGeneratedDocument]);

  const handleEmail = async () => {
    if (!companyInfo.email) {
      return setSnackbar(
        {
          open: true,
          message: SNACKBAR_MESSAGES.COMPANY_EMAIL_INVALID,
          variant: "error",
        },
        5000
      );
    }

    const payloadData = getDownloadDocumentPayload({
      documentType: DownloadDocument.PROPOSAL,
      financialCalculations: proposal.quotation.calculations,
      financialValues: proposal.quotation.formValues,
      proposalValues: proposal.proposal,
      setups: {
        financeTypes: financeTypes,
        productTypes: productTypes,
        clientTypes: clientTypes,
        assetSubTypes: assetSubTypes,
        assetConditions: assetConditions,
        assetCategories: assetCategories,
        assetTypes: assetTypes,
        lenders: lenders,
      },
      companyId: companyInfo.id,
    });
    const payload: EmailNotificationPayload = {
      source_email: companyInfo.email,
      email: "",
      data: {
        ...payloadData,
        customer_name:
          clientType !== ClientTypes.INDIVIDUAL
            ? proposal?.proposal?.customerDetails?.companyName
            : [
                proposal?.proposal?.customerDetails?.firstName,
                proposal?.proposal?.customerDetails?.lastName,
              ]
                .filter(Boolean)
                .join(" "),
        company_id: companyInfo?.id,
        reference_number: proposal.proposal.identifier,
        customer_reference_id: proposal.proposal.customerDetails?.identifier,
      },
      email_subject: "Compliance Documents",
      cc_emails: [],
      bcc_emails: [],
      email_attachment_keys: [],
    };
    try {
      await sendEmailNotification({
        eventName: Events.GENERATE_COMPLIANCE_DOCUMENTS,
        payload,
      });
      setSnackbar(
        {
          message: "Email Sent!",
          open: true,
          variant: null,
        },
        10000
      );
    } catch (e) {
      setSnackbar(
        {
          message: SNACKBAR_MESSAGES.ERROR_WHILE_SENDING_EMAIL,
          open: true,
          variant: "error",
        },
        10000
      );
    }
  };

  const handlePreview = async (key: string) => {
    try {
      const templateUrl = await previewDocs(key);
      window.open(templateUrl, "_blank");
    } catch (e) {
      setSnackbar(
        {
          message: "Error occurred while Downloading Document",
          open: true,
          variant: "error",
        },
        10000
      );
    }
  };

  const handleDownloads = () => {
    generatedDocuments.forEach((item) => {
      item.documents.forEach((document) => {
        handlePreview(document?.generated_document_uri);
      });
    });
  };

  return (
    <AddSignatoriesWrap theme={theme} className="add-signatories-wrap">
      <Stack
        paddingMd={3}
        paddingXs={3}
        title={
          generatedDocumentSuccess
            ? "Compliance Documents generated successfully!"
            : ""
        }
      >
        <SignatoriesList
          commonFilterParams={commonFilterParams}
          complianceDocuments={complianceDocuments}
          signatories={signatories}
          isGeneratedDocument={documentsGenerated}
        />
        <Box
          theme={theme}
          className="action-area"
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          gap={{ xs: 1.25, md: 2 }}
          mt={2}
          flexDirection={{ xs: "column", md: "row" }}
        >
          <Button
            theme={theme}
            type="button"
            secondary
            onClick={handleEmail}
            text={"Email Compliance Documents"}
            className="w-m-100"
            disabled={!isButtonEnabled}
          />
          <Button
            theme={theme}
            type="button"
            primary
            onClick={handleDownloads}
            text={"Download Compliance Documents"}
            className="w-m-100"
            disabled={!isButtonEnabled}
          />
        </Box>
      </Stack>
    </AddSignatoriesWrap>
  );
};
