import { useTheme } from "@mui/material";
import { Dialog } from "@ntpkunity/controls";
import { ReactNode } from "react";
import { FullWindowDialogContentWrap } from "./full-window-dialog.style";

export const FullWindowDialog = ({
  children,
  onClose,
  open,
}: {
  children?: ReactNode;
  onClose?: () => void;
  open?: boolean;
}) => {
  const theme = useTheme();
  return (
    <Dialog
      theme={theme}
      size="xl"
      noFooter
      onCloseDialog={onClose}
      open={open}
      variant="full-window"
    >
      <FullWindowDialogContentWrap
        theme={theme}
        className="full-window-dialog-content-wrap"
      >
        {children}
      </FullWindowDialogContentWrap>
    </Dialog>
  );
};
