import { useQuery } from "react-query";
import { QUERY_KEYS } from "@helpers/query";
import { getProductTypes } from "@services/index.service";
import { ProductTypeSetupRes } from "@_types/setups";
import useGetCompanyByTenantId from "./useGetCompanyProfile";

const useGetProductTypes = () => {
  const { data: companyInfo } = useGetCompanyByTenantId();
  const companyId = companyInfo?.id;

  return useQuery<number, unknown, ProductTypeSetupRes>({
    queryKey: [QUERY_KEYS.GET_PRODUCT_TYPES, companyId],
    queryFn: () => getProductTypes(companyId),
    enabled: !!companyId,
  });
};

export default useGetProductTypes;
