import { TableCell, TableRow, useTheme } from "@mui/material";
import {
  Box,
  Button,
  DataTable,
  Icon,
  Input,
  Pagination,
  Typography,
} from "@ntpkunity/controls";
import { DataTableWrap } from "@styles/data-table-wrap";
import { useProposalFieldArrays } from "@contexts/proposal-form";
import { AssetTableRow } from "@components/proposal/asset-details";
import { AssetDetailsTableProps } from "./asset-details-table.props";

const AssetDetailsTable = ({
  setEditAssetId,
  loadedFromSummary = false,
}: AssetDetailsTableProps) => {
  const theme = useTheme();
  const {
    assetMethods: { fields: assets },
  } = useProposalFieldArrays();
  return (
    <DataTableWrap theme={theme} className="table-pagination-button">
      <Box theme={theme} className="scroll">
        <Box className="scroll-hide spr-border" theme={theme} />
      </Box>
      <DataTable
        theme={theme}
        theadChildren={
          <>
            <TableRow>
              <TableCell>Asset Sub-Type</TableCell>
              {!loadedFromSummary && <TableCell>Asset Description</TableCell>}
              <TableCell>Supplier Name</TableCell>
              <TableCell>Quantity</TableCell>
              <TableCell>RV/Balloon Amount</TableCell>
              <TableCell>Asset Cost</TableCell>
              <TableCell className="action-cell fixed-cell" />
            </TableRow>
            <TableRow className="filters-row">
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    type={""}
                    startAdornment={""}
                    endAdornment={""}
                    id={""}
                    placeholder="Search"
                  ></Input>
                  <Button
                    defaultBtn
                    theme={theme}
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              {!loadedFromSummary && (
                <TableCell>
                  <Box theme={theme} className="table-filter">
                    <Input
                      theme={theme}
                      fullWidth={true}
                      type={""}
                      startAdornment={""}
                      endAdornment={""}
                      id={""}
                      placeholder="Search"
                    ></Input>
                    <Button
                      defaultBtn
                      theme={theme}
                      iconText={<Icon name="IconFilter" />}
                    ></Button>
                  </Box>
                </TableCell>
              )}

              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    type={""}
                    startAdornment={""}
                    endAdornment={""}
                    id={""}
                    placeholder="Search"
                  ></Input>
                  <Button
                    defaultBtn
                    theme={theme}
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    type={""}
                    startAdornment={""}
                    endAdornment={""}
                    id={""}
                    placeholder="Search"
                  ></Input>
                  <Button
                    defaultBtn
                    theme={theme}
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    type={""}
                    startAdornment={""}
                    endAdornment={""}
                    id={""}
                    placeholder="Search"
                  ></Input>
                  <Button
                    defaultBtn
                    theme={theme}
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell>
                <Box theme={theme} className="table-filter">
                  <Input
                    theme={theme}
                    fullWidth={true}
                    type={""}
                    startAdornment={""}
                    endAdornment={""}
                    id={""}
                    placeholder="Search"
                  ></Input>
                  <Button
                    defaultBtn
                    theme={theme}
                    iconText={<Icon name="IconFilter" />}
                  ></Button>
                </Box>
              </TableCell>
              <TableCell className="action-cell fixed-cell" />
            </TableRow>
          </>
        }
        tbodyChildren={
          <>
            {assets.length ? (
              assets.map((asset, index) => (
                <AssetTableRow
                  key={asset.id}
                  asset={asset}
                  index={index}
                  loadedFromSummary={loadedFromSummary}
                  setEditAssetId={setEditAssetId}
                />
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={loadedFromSummary ? 6 : 7}>
                  <Typography
                    theme={theme}
                    variant="body2"
                    component="span"
                    display="block"
                    textAlign="center"
                  >
                    No Assets Found.
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </>
        }
      />
    </DataTableWrap>
  );
};

export default AssetDetailsTable;
