import { useState, useMemo, useCallback } from "react";
import { ProposalTabs, FinanceType } from "@helpers/enum";
import { TProposalForm } from "@_types";
import { UseFormWatch } from "react-hook-form";
import { useWorkflowContext } from "@components/workflows";
import { useGetFinanceTypeName } from "@hooks";

const useProposalStepItems = ({
  watch,
  defaultIndex = 0,
}: {
  watch: UseFormWatch<TProposalForm>;
  defaultIndex?: number;
}) => {
  const [activeIndex, setActiveIndex] = useState(defaultIndex);
  const financeCode: string = watch("proposalDetails.financeType");
  const financeType: string = useGetFinanceTypeName(financeCode);
  const isAssetsTabDisabled: boolean = financeType === FinanceType.LOAN;
  const {
    meta_data: { tabs },
  } = useWorkflowContext();

  const stepItems = useMemo(
    () =>
      tabs.map((tab) => {
        if (tab === ProposalTabs.ASSET_DETAILS)
          return { title: tab, disabled: isAssetsTabDisabled };
        else return { title: tab, disabled: false };
      }),
    [isAssetsTabDisabled]
  );

  const getStepSize = useCallback(
    (type: "inc" | "dec") =>
      stepItems[type === "inc" ? activeIndex + 1 : activeIndex - 1].disabled
        ? 2
        : 1,
    [activeIndex, stepItems]
  );

  const getNextIndex = useCallback(() => {
    const stepSize = getStepSize("inc");
    return activeIndex + stepSize < stepItems.length
      ? activeIndex + stepSize
      : null;
  }, [activeIndex, getStepSize]);

  const getPrevIndex = useCallback(() => {
    const stepSize = getStepSize("dec");
    return activeIndex - stepSize >= 0 ? activeIndex - stepSize : null;
  }, [activeIndex, getStepSize]);

  const getNextButtonText = useCallback(() => {
    const isLastStep = activeIndex + 1 === stepItems.length;
    if (isLastStep) return "Submit Proposal";
    return `Next: ${stepItems[getNextIndex()].title}`;
  }, [activeIndex, getNextIndex]);

  const showPreviousStep = useCallback(() => !getPrevIndex(), [getPrevIndex]);

  const goToNextTab = useCallback(
    () => setActiveIndex(getNextIndex()),
    [getNextIndex]
  );

  const goToPrevTab = useCallback(
    () => setActiveIndex(getPrevIndex()),
    [getPrevIndex]
  );

  const activateTab = useCallback(
    (tab: ProposalTabs) => {
      const tabIndex = stepItems.findIndex((step) => step.title === tab);
      if (tabIndex <= -1) {
        return;
      }
      setActiveIndex(tabIndex);
    },
    [stepItems]
  );

  return {
    activeIndex,
    setActiveIndex,
    stepItems,
    goToNextTab,
    goToPrevTab,
    getNextButtonText,
    showPreviousStep,
    activateTab,
  };
};

export default useProposalStepItems;
