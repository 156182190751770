import { useForm, useFormContext, FormProvider } from "react-hook-form";
import { TDirector, TProposalForm } from "@_types";
import { useProposalFieldArrays } from "@contexts/proposal-form";
import { AddDirectorDialogProps } from "./add-director-dialog.props";
import DirectorFormDialog from "../director-form-dialog/director-form-dialog.component";
import { useGetClientTypeName } from "@hooks/useSetupNames";

const AddDirectorDialog = ({ open, setOpenDialog }: AddDirectorDialogProps) => {
  const { watch } = useFormContext<TProposalForm>();
  const clientTypeCode = watch("customerDetails.clientType");
  const clientTypeName = useGetClientTypeName(clientTypeCode);

  const formMethods = useForm<TDirector>();
  const {
    directorMethods: { append },
  } = useProposalFieldArrays();

  const closeDialog = () => {
    formMethods.reset();
    setOpenDialog(false);
  };

  const handleSaveDirector = () => {
    append({
      firstName: "",
      lastName: "",
      emailAddress: "",
      contactNumber: "",
      address: "",
      ...formMethods.getValues(),
    });
    closeDialog();
  };
  return (
    <FormProvider {...formMethods}>
      <DirectorFormDialog
        clientType={clientTypeName}
        open={open}
        closeDialog={closeDialog}
        handleSaveDirector={handleSaveDirector}
      />
    </FormProvider>
  );
};

export default AddDirectorDialog;
