import { useState } from "react";

export const useDialogState = <TData>() => {
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  const [dialogData, setDialogData] = useState<TData | null>(null);

  const openDialog = (dialogData?: TData) => {
    setDialogData(dialogData);
    setDialogOpen(true);
  };

  const closeDialog = () => {
    setDialogOpen(false);
    setDialogData(null);
  };

  return {
    dialogOpen,
    dialogData,
    openDialog,
    closeDialog,
  };
};
