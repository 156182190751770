import { useQuery } from "react-query";
import { QUERY_KEYS } from "@helpers/query";
import { searchAddress } from "@services/hubex.service";
import { SearchAddressResponse } from "@_types/proposal";

const useSearchAddress = (
  xServiceProvider: string,
  postCode: string,
  onSuccess: (data: SearchAddressResponse[]) => void
) => {
  return useQuery({
    queryKey: [QUERY_KEYS.SEARCH_ADDRESS, postCode],
    queryFn: () => searchAddress(xServiceProvider, postCode),
    enabled: !!xServiceProvider && !!postCode,
    onSuccess: onSuccess,
    cacheTime: 0,
  });
};

export default useSearchAddress;
