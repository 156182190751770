import { Stack } from "@components";
import { TableCell, TableRow, useTheme } from "@mui/material";
import {
  Box,
  Button,
  DataTable,
  Icon,
  Menu,
  Typography,
} from "@ntpkunity/controls";
import { DataTableWrap } from "@styles/data-table-wrap";
import { CreditDocumentRow } from "@components/proposal/credit-documents";
import { CreditDocumentTableProps } from "./credit-document-table.props";
import { useProposalFieldArrays } from "@contexts/proposal-form";

const CreditDocumentTable = ({
  setOpenDialog,
  setOpenViewDialog,
  setEditDocumentId,
  showAddBtn,
}: CreditDocumentTableProps) => {
  const {
    documentMethods: { fields: documents },
  } = useProposalFieldArrays();
  const theme = useTheme();
  return (
    <Stack
      paddingXs={3}
      paddingMd={3}
      marginTopMd={2}
      marginTopXs={2}
      title="Credit Documents"
      actionArea={
        <Box theme={theme} display="none">
          <Menu
            theme={theme}
            disablePortal={true}
            handleOptionClick={() => {}}
            options={[
              {
                optionText: "Download All",
                optionkey: "download-all",
              },
              {
                optionText: "Upload Pack",
                optionkey: "upload-pack",
              },
            ]}
            render={(cb) => (
              <>
                <Button
                  theme={theme}
                  iconText={<Icon name="MoreIcon" />}
                  type="button"
                  defaultBtn
                  onClick={cb}
                />
              </>
            )}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          />
        </Box>
      }
    >
      <Box theme={theme} mt={2}>
        <DataTableWrap theme={theme} className="no-filters">
          <Box theme={theme} className="scroll">
            <Box className="scroll-hide spr-border" theme={theme} />
          </Box>
          <DataTable
            theme={theme}
            theadChildren={
              <>
                <TableRow>
                  <TableCell>Document Name</TableCell>
                  <TableCell>Document Type</TableCell>
                  <TableCell>Document State</TableCell>
                  <TableCell>Document Status</TableCell>
                  <TableCell className="action-cell fixed-cell" />
                </TableRow>
              </>
            }
            tbodyChildren={
              <>
                {documents.length ? (
                  documents.map((doc) => (
                    <CreditDocumentRow
                      key={doc.id}
                      {...doc}
                      setOpenDialog={setOpenDialog}
                      setEditDocumentId={setEditDocumentId}
                      setOpenViewDialog={setOpenViewDialog}
                    />
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={5}>
                      <Typography
                        theme={theme}
                        variant="body2"
                        component="span"
                        display="block"
                        textAlign="center"
                      >
                        No Documents Found.
                      </Typography>
                    </TableCell>
                  </TableRow>
                )}
              </>
            }
          />
        </DataTableWrap>
      </Box>
      {showAddBtn && (
        <Box theme={theme} mt={2} display="none">
          <Button
            theme={theme}
            secondary
            disabled={true}
            fullWidth
            text="Add New Document"
            onClick={() => setOpenDialog(true)}
          />
        </Box>
      )}
    </Stack>
  );
};

export default CreditDocumentTable;
