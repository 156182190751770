import { useTheme } from "@mui/material";
import { Box, Button, Dialog, Grid, Input } from "@ntpkunity/controls";
import { DirectorFormDialogProps } from "./director-form-dialog.props";
import { TInputDef } from "@components/proposal/proposal-details/types";
import ControlledFormElement from "@components/controlled-form-element/controlled-form-element.component";
import { ClientTypes } from "@helpers/enum";
import { useSnackbarContext } from "@contexts/snackbar";
import { DIRECTOR_ROLES, PARTNER_ROLES } from "@helpers/const";

const fullWidthFields = ["emailAddress", "contactNumber", "address", "role"];

const useDirectorsInputDefs = (clientType: string) => {
  return [
    {
      name: "role",
      formElement: "Select",
      label: "Role",
      items:
        clientType === ClientTypes.PARTNERSHIP ? PARTNER_ROLES : DIRECTOR_ROLES,
    },
    {
      name: "firstName",
      formElement: "Input",
      placeHolder: "First Name",
      label: "First Name",
      type: "text",
    },
    {
      name: "lastName",
      formElement: "Input",
      placeHolder: "Last Name",
      label: "Last Name",
      type: "text",
    },
    {
      name: "emailAddress",
      formElement: "Input",
      placeHolder: "Email",
      label: "Email",
      type: "text",
    },
    {
      name: "contactNumber",
      formElement: "Input",
      placeHolder: "Contact Number",
      label: "Contact Number",
      type: "text",
    },
    {
      name: "address",
      formElement: "Input",
      placeHolder: "Address",
      label: "Address",
      type: "text",
    },
  ] as TInputDef[];
};

const DirectorFormDialog = ({
  clientType,
  open,
  isEditMode = false,
  closeDialog,
  handleSaveDirector,
}: DirectorFormDialogProps) => {
  const theme = useTheme();
  const { setSnackbar } = useSnackbarContext();

  const inputDefs = useDirectorsInputDefs(clientType);

  const clientTypeText =
    clientType === ClientTypes.PARTNERSHIP ? "Partners" : "Directors";

  const _handleSaveDirector = () => {
    handleSaveDirector();
    setSnackbar({ open: true, message: "Saved Successfully." }, 2000);
  };

  return (
    <Dialog
      theme={theme}
      size={"sm"}
      open={open}
      onCloseDialog={() => closeDialog()}
      title={isEditMode ? "View/Edit" : `Add ${clientTypeText}`}
      customFooter={
        <>
          <Button
            theme={theme}
            secondary
            text="Cancel"
            type="button"
            onClick={() => closeDialog()}
          />
          <Button
            theme={theme}
            primary
            text={`Save ${clientTypeText}`}
            type="button"
            onClick={_handleSaveDirector}
          />
        </>
      }
    >
      <Box theme={theme}>
        <Grid theme={theme} container item spacing={3}>
          {inputDefs.map((item, index) => {
            return (
              <Grid
                key={`${index} - ${item.name}`}
                theme={theme}
                item
                xs={12}
                md={fullWidthFields.includes(item.name) ? false : 6}
              >
                <ControlledFormElement {...item} />
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </Dialog>
  );
};

export default DirectorFormDialog;
