import { useMemo } from "react";
import { TProposalForm } from "@_types";
import { useFormContext } from "react-hook-form";

const useSumOfRvBalloonAmount = () => {
  const { watch } = useFormContext<TProposalForm>();
  const assets = watch("assets");

  const sumRvBalloonAmount = () => {
    return assets.reduce(
      (total, asset) => total + Number(asset.totalRvBalloonAmount),
      0
    );
  };

  const sumOfRvBalloonAmount = useMemo(() => sumRvBalloonAmount(), [assets]);

  return sumOfRvBalloonAmount;
};

export default useSumOfRvBalloonAmount;
