import { useQuery } from "react-query";
import { QUERY_KEYS } from "@helpers/query";
import { getClientTypes } from "@services/index.service";
import { ClientTypeSetupRes } from "@_types/setups";
import useGetCompanyByTenantId from "./useGetCompanyProfile";

const useGetClientTypes = () => {
  const { data: companyInfo } = useGetCompanyByTenantId();

  return useQuery<number, unknown, ClientTypeSetupRes>({
    queryKey: [QUERY_KEYS.GET_CLIENT_TYPES, companyInfo?.id],
    queryFn: () => getClientTypes(companyInfo?.id),
    enabled: !!companyInfo?.id,
  });
};

export default useGetClientTypes;
