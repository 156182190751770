import { useQuery } from "react-query";
import { QUERY_KEYS } from "@helpers/query";
import { getAssetConditions } from "@services/index.service";
import { AssetConditionsSetupRes } from "@_types/setups";
import useGetCompanyByTenantId from "./useGetCompanyProfile";

const useGetAssetConditions = () => {
  const { data: companyInfo } = useGetCompanyByTenantId();
  const companyId = companyInfo?.id;

  return useQuery<number, unknown, AssetConditionsSetupRes>({
    queryKey: [QUERY_KEYS.GET_ASSET_CONDITIONS, companyId],
    queryFn: () => getAssetConditions(companyId),
    enabled: !!companyId,
  });
};

export default useGetAssetConditions;
